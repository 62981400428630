import store from '../redux/store';
import {setLoggedUser} from '../redux/user';

class APIFailure {
  message: string;
  body: any;
  constructor(message: string, body: any) {
    this.message = message;
    this.body = body;
  }
}

export function apiPath(
  strings: readonly string[],
  ...values: readonly string[]
): (query?: Readonly<Record<string, string>>) => string {
  let s = strings[0];
  for (let i = 0; i < values.length; i++) {
    s += `${encodeURIComponent(values[i])}${strings[i + 1]}`;
  }
  const path = s;
  return query => {
    let queryString = '';
    Object.entries(query || {}).forEach(([key, value]) => {
      if (queryString) queryString += '&';
      queryString += encodeURIComponent(key);
      queryString += '=';
      queryString += encodeURIComponent(value);
    });
    if (!queryString) return path;
    return `${path}?${queryString}`;
  };
}

export async function apiFetch<T = unknown>(path: string, options: RequestInit): Promise<T> {
  if (path[0] !== '/') throw new Error('invalid api path');
  const url = `/service/api${path}`;
  const response = await fetch(url, {
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    ...options,
  });
  if (response.status === 401) {
    deleteCredentials();
  }

  let body: any;
  try {
    if (!response.headers.get('content-type')?.startsWith('application/json')) {
      throw new Error('Response Content-Type is not application/json');
    }
    body = await response.json();
  } catch (error) {
    console.log('API response was not valid JSON', error);
    response.text().then(
      text => {
        console.log('Bad API response text:', text);
      },
      error2 => {
        console.log('Cannot read bad API response text', error2);
      }
    );
    throw new Error('Invalid API response');
  }
  if (!response.ok) {
    throw new APIFailure(body.error, body);
  }
  return body;
}

/**
 * Used for simply storing responses for API requests for recall later.
 * Will clear on React application page reload.
 */
const simpleCache: Map<string, Promise<any>> = new Map();
export function simpleCacheProvider<T>(name: string, get: () => Promise<T>): Promise<T> {
  let promise = simpleCache.get(name);
  if (!promise) {
    promise = get();
    simpleCache.set(name, promise);
    // This .catch() deliberately happens in the background - there is not meant to be an `await` here.
    promise.catch(error => {
      console.log(`simpleCache fetch for ${name} failed`, error);
      // If it failed, drop the promise from simpleCache to permit trying again.
      simpleCache.delete(name);
    });
  }
  return promise;
}

export type Config = {
  googleMapsKey: string;
  serverName: string;
  version: string;
  systemName: string;
};

export function getConfig(): Promise<Config> {
  return simpleCacheProvider('config', () => apiFetch('/config', {method: 'GET'}));
}

function deleteCredentials() {
  store.dispatch(setLoggedUser(null));
}
