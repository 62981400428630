import {Material, Product, MachineModel, UID, ProductImage} from '../types';
import {apiFetch} from './api.common';
import {acetate, fdm, handle, mask, rubberband, shield} from '../images';

export type ApiProduct = {
  id: UID;

  category: string;
  subcategory: string | null;
  name: string;
  unit: string | null;
  image: string | null;
};

type ApiProductListResponse = {
  items: ApiProduct[];
  products: ApiProduct[];
};

export async function listMaterials(): Promise<Material[]> {
  // TODO: Updated this function to apply transformProduct or remove it and use listProducts instead
  const {items} = await apiFetch('/products/list-category/material', {
    method: 'GET',
  });
  return items;
}

export async function listProducts(): Promise<Product[]> {
  const {items} = await apiFetch<ApiProductListResponse>('/products/list', {
    method: 'GET',
  });
  return items.map(e => transformProduct(e));
}

export async function listMachinesModels(): Promise<MachineModel[]> {
  const {items} = await apiFetch('/machineModels/list', {
    method: 'GET',
  });
  return items;
}

const PRODUCT_IMAGES: Map<String, ProductImage> = new Map(
  Object.entries({
    acetate,
    fdm,
    handle,
    mask,
    rubberband,
    shield,
  }).map(([key, img]) => [key, new ProductImage(img)])
);

export function transformProduct(apiProduct: ApiProduct): Product {
  const {id, category, subcategory, name, unit} = apiProduct;
  const image = (apiProduct.image && PRODUCT_IMAGES.get(apiProduct.image)) || null;
  return {id, category, subcategory, name, unit, image};
}
