import TransportIcon from './TransportIcon';
import SignUpIcon from './SignUpIcon';
import RequestIcon from './RequestIcon';
import PpeMaskIcon from './PpeMaskIcon';
import ProfileIcon from './ProfileIcon';
import PpeHandleIcon from './PpeHandleIcon';
import OtherIcon from './OtherIcon';
import PpeFaceShieldIcon from './PpeFaceShieldIcon';
import OfferIcon from './OfferIcon';
import MaterialRubberBandIcon from './MaterialRubberBandIcon';
import OfferSubmitttedIcon from './OfferSubmittedIcon';
import MaterialProviderIcon from './MaterialProviderIcon';
import MarkerIcon from './MarkerIcon';
import MaterialAcetateIcon from './MaterialAcetateIcon';
import LocationIcon from './LocationIcon';
import InfoIcon from './InfoIcon';
import HealthWorkerIcon from './HealthWorkerIcon';
import DemandIcon from './DemandIcon';
import NextIcon from './NextIcon';
import PreviousIcon from './PreviousIcon';
import AddIcon from './AddIcon';
import CrossIcon from './CrossIcon';
import PreviousBasicIcon from './PreviousBasicIcon';
import NextBasicIcon from './NextBasicIcon';
import {ComponentType} from 'react';
import CrosshairIcon from './CrosshairIcon';
import ReloadIcon from './ReloadIcon';
import CheckIcon from './CheckIcon';
import LogoutIcon from './LogoutIcon';

export type IconName =
  | 'cross'
  | 'next'
  | 'nextBasic'
  | 'previous'
  | 'previousBasic'
  | 'add'
  | 'transport'
  | 'signUp'
  | 'request'
  | 'ppeMask'
  | 'profile'
  | 'ppeHandle'
  | 'other'
  | 'ppeFaceShield'
  | 'offer'
  | 'materialRubberBand'
  | 'offerSubmitted'
  | 'materialProvider'
  | 'maker'
  | 'materialAcetate'
  | 'location'
  | 'info'
  | 'healthWorker'
  | 'demand'
  | 'crosshair'
  | 'reload'
  | 'check'
  | 'logout';

const icons: {[key in IconName]: ComponentType<any>} = {
  cross: CrossIcon,
  next: NextIcon,
  previous: PreviousIcon,
  nextBasic: NextBasicIcon,
  previousBasic: PreviousBasicIcon,
  add: AddIcon,
  transport: TransportIcon,
  signUp: SignUpIcon,
  request: RequestIcon,
  ppeMask: PpeMaskIcon,
  profile: ProfileIcon,
  ppeHandle: PpeHandleIcon,
  other: OtherIcon,
  ppeFaceShield: PpeFaceShieldIcon,
  offer: OfferIcon,
  materialRubberBand: MaterialRubberBandIcon,
  offerSubmitted: OfferSubmitttedIcon,
  materialProvider: MaterialProviderIcon,
  maker: MarkerIcon,
  materialAcetate: MaterialAcetateIcon,
  location: LocationIcon,
  info: InfoIcon,
  healthWorker: HealthWorkerIcon,
  demand: DemandIcon,
  crosshair: CrosshairIcon,
  reload: ReloadIcon,
  check: CheckIcon,
  logout: LogoutIcon,
};

export default icons;
