import React from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from '../urls';
import URLS_REQUESTS from './urls';
import {findUserOpenRequests} from '../api/api.tasks';
import TaskList from '../common/TaskList';
import withUser from '../common/withUser';
import {User} from '../types';

type PropsType = {
  user: User;
};

const MyRequestsPage = (props: PropsType) => {
  const {user} = props;

  return (
    <AppContainer>
      <PageHeader text="Requests I've made" iconName="previous" to={URLS_REQUESTS.requests} bottomMargin={false} />
      <TaskList
        className={'limit-width'}
        taskProvider={findUserOpenRequests}
        userId={user.id}
        moreInfo={URLS.task_info}
      />
    </AppContainer>
  );
};

export default withUser(MyRequestsPage);
