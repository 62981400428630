import React from 'react';
import {Alert} from 'reactstrap';

type PropsType = {
  errors: {[key: string]: string};
  keys: Array<string>;
  prefix?: string;
};

const ValidationError = (props: PropsType) => {
  const {errors, keys, prefix} = props;
  const strings = keys.map(k => errors[k]).filter(Boolean);
  if (strings.length === 0) return null;
  return <Alert color="danger">{[prefix, strings[0]].filter(Boolean).join(' ')}</Alert>;
};

export default React.memo(ValidationError);

export const mapValidationErrors = (errors: Array<{param: string; msg: string}>) =>
  Object.assign({}, ...errors.map((e: any) => ({[e.param]: e.msg})));
