import React, {useEffect, useState} from 'react';
import {getConfig} from '../api/api.common';
import ErrorFree from './ErrorFree';
import ConditionalSpinner from './ConditionalSpinner';

type HasApiKey = {
  googleMapsApiKey: string;
};

type OmitKey<T> = Omit<T, 'googleMapsApiKey'>;

function withGoogleMapsApiKey<T extends HasApiKey>(
  Component: React.ComponentType<T>
): React.FunctionComponent<OmitKey<T>> {
  return (props: OmitKey<T>) => {
    const [apiKey, setApiKey] = useState<string>();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      const doAsync = async () => {
        try {
          const config = await getConfig();
          const key = config.googleMapsKey;
          if (key) {
            setApiKey(key);
          } else {
            setError('Could not load map key from server configuration.');
          }
        } catch (e) {
          setError('Could not load server configuration. ' + e.message);
        }
      };
      doAsync();
    }, []);

    return (
      <ErrorFree error={error}>
        <ConditionalSpinner spinner={!apiKey}>
          <Component {...(props as T)} googleMapsApiKey={apiKey} />
        </ConditionalSpinner>
      </ErrorFree>
    );
  };
}

export default withGoogleMapsApiKey;
