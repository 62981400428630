import React, {ReactNode} from 'react';
import {Container} from 'reactstrap';
import {getLoggedUser} from '../redux/user';
import {useOurSelector} from '../redux/rootReducer';
import UserTabBar from './UserTabBar';
import './AppContainer.css';

type PropsType = {
  children: ReactNode;
};

const AppContainer = (props: PropsType) => {
  const {children} = props;
  const loggedUser = useOurSelector(getLoggedUser);

  return (
    <Container fluid className={'AppContainer p-0'}>
      <div className={'content'}>{children}</div>
      <UserTabBar user={loggedUser} />
    </Container>
  );
};

export default AppContainer;
