import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};
const SignUpIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="m20.2988793 21.5.7925595.0380608.7746485.0761215.7746485.1320932.7746486.1701541.7365877.2238868.7567376.2641865.7186768.3022473.698527.3582189.2611422.1537039c-.7344018.8580567-1.1779648 1.9724375-1.1779648 3.1904179 0 2.7112161 2.1978749 4.9090909 4.9090909 4.9090909.8351857 0 1.6216585-.2085647 2.3101764-.576452l.2037074.7413852.170154.7746485.1141823.7746485.0761215.7746485.017911.7724097h-26.2104344v-.3873243l.05597171-.7746485.09403248-.7746485.13209325-.7746485.18806496-.7746486.22388686-.7365877.28433631-.7365878.3201582-.7164379.35821897-.698527.39627974-.6716606.44777371-.6425553.47240127-.6224054.51046204-.5843447.5485228-.5664338.5865836-.510462.6224054-.4903122.6425553-.4477738.6716606-.4164295.698527-.3783688.698527-.3201582.7365877-.2820974.7365878-.2462756.7746485-.2082148.7567376-.1500042.7746485-.0940324.7925595-.0559718z"
          stroke={color}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="1.1"
        />
        <g stroke={color}>
          <circle cx="20.5" cy="12.909091" r="4.909091" strokeWidth="1.1" />
          <circle cx="30.318182" cy="26.409091" r="4.909091" strokeWidth="1.1" />
          <path d="m30.318182 24.409091v4" strokeLinecap="round" />
          <path d="m30.318182 24.409091v4" strokeLinecap="round" transform="matrix(0 -1 1 0 3.909091 56.727273)" />
        </g>
      </g>
    </svg>
  );
};
export default SignUpIcon;
