import React from 'react';

type PropsType = {
  text: string;
  className?: string;
};

const Badge = (props: PropsType) => {
  const {text, className} = props;

  const classes = 'bg-danger text-white rounded-circle btn-small d-flex align-items-center justify-content-center';

  return (
    <div className={[classes, className].join(' ')}>
      <span className={'h2 m-0'}>{text}</span>
    </div>
  );
};

export default Badge;
