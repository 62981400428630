import React, {FC, useMemo} from 'react';
import {TaskStatus, Task} from '../types';
import './TaskStatusIndicator.css';
import {taskStatus} from './util/taskUtil';

type Props = {
  className?: string;
  task: Task;
};

const TaskStatusIndicator: FC<Props> = props => {
  const {className, task} = props;

  const status = useMemo(() => taskStatus(task), [task]);

  const label: {[status in TaskStatus]: string} = {
    invalid: 'Invalid',
    open_offer: 'Open',
    open_request: 'Open',
    awaiting_transporter: 'Awaiting transporter',
    awaiting_approval: 'Awaiting approval',
    approved: 'Approved',
    collection_complete: 'Collection complete',
    delivery_complete: 'Delivered',
  };

  return (
    <div className={`TaskStatusIndicator ${className}`}>
      <p className={status}>{label[status]}</p>
    </div>
  );
};

export default TaskStatusIndicator;
