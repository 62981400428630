import {useMemo} from 'react';

let UNIQUE = 0;
export function useUniqueId(name: string) {
  return useMemo(() => {
    const id = UNIQUE;
    UNIQUE += 1;
    return `${name}-${id}`;
  }, [name]);
}
