import React, {useCallback, FC} from 'react';
import {Container, Form} from 'reactstrap';
import EditLocationForm from '../common/EditLocationForm';
import {UserRequest} from '../api/api.users';
import {createCoordinate} from '../common/util/mapUtil';

type Props = {
  index: number;
  initialData: UserRequest;
  onSubmit: (index: number, data: UserRequest) => void;
  validationErrors: {[key: string]: string};
};

const SignupLocationForm: FC<Props> = props => {
  const {index, initialData, onSubmit, validationErrors} = props;

  const handleLocationFormResult = useCallback(
    result => {
      onSubmit(index, {
        street: result.street,
        area: result.area,
        postcode: result.postcode,
        latitude: result.coordinate?.latitude,
        longitude: result.coordinate?.longitude,
      });
    },
    [index, onSubmit]
  );

  return (
    <Container className="p-0 pt-3">
      <Form>
        <EditLocationForm
          coordinate={createCoordinate(initialData.latitude, initialData.longitude)}
          street={initialData.street}
          area={initialData.area}
          postcode={initialData.postcode}
          onResult={handleLocationFormResult}
          locationHeader={<p className={'mx-3 font-weight-bold font-italic'}>Choose your location on the map</p>}
          addressHeader={<p className={'mx-3 font-weight-bold font-italic'}>Confirm or edit your address</p>}
          buttonText="Next"
          validationErrors={validationErrors}
        />
      </Form>
    </Container>
  );
};

export default SignupLocationForm;
