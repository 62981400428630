import React from 'react';
import {Container} from 'reactstrap';
import {Task, UID} from '../types';
import {isValid} from './util/taskUtil';
import './TaskDetail.css';
import {formatDate} from './util/dateUtil';
import PartCard from './PartCard';

type PropsType = {
  /* The task whose info is displayed. */
  task: Task;
  /* ID of the user that is viewing the task. Presented information and available actions depend on this. */
  userId: UID;
};

/**
 * Displays information about the given task. Data shown is personalized for the given user and is abbreviated or
 * extended depending on the given params.
 */
const TaskDetail = (props: PropsType) => {
  const {task} = props;

  if (!isValid(task)) {
    console.warn(`Skipping task ${task.id}. Invalid state`);
    return null;
  }

  return (
    <Container className="pt-3">
      {task.offer && (
        <PartCard
          title="Offer"
          data={[
            {
              'Collect from': task.offer?.pickupName,
              Address: [task.offer?.pickupStreet, task.offer?.pickupArea, task.offer?.pickupPostcode]
                .filter(Boolean)
                .join(', '),
              Times: task.offer?.pickupInterval,
            },
            {
              Notes: task.offer?.pickupNotes,
              Offered: task.dates.offeredAt && formatDate(task.dates.offeredAt),
              Approved: task.dates.approvedAt && formatDate(task.dates.approvedAt),
            },
            {
              Organiser: task.offer?.user?.name,
              Organisation: task.offer?.user?.organisation,
              Assurances: task.offer?.user?.assurances,
              About: task.offer?.user?.aboutme,
              Address: [task.offer?.user?.street, task.offer?.user?.area, task.offer?.user?.postcode]
                .filter(Boolean)
                .join(', '),
              Email: task.offer?.user?.email,
              Phone: task.offer?.user?.phone,
            },
          ]}
        />
      )}
      {task.request && (
        <PartCard
          title="Request"
          data={[
            {
              'Deliver to': task.request?.deliveryName,
              Address: [task.request?.deliveryStreet, task.request?.deliveryArea, task.request?.deliveryPostcode]
                .filter(Boolean)
                .join(', '),
              Times: task.request?.deliveryInterval,
            },
            {
              Notes: task.request?.deliveryNotes,
              Requested: task.dates.requestedAt && formatDate(task.dates.requestedAt),
              Approved: task.dates.approvedAt && formatDate(task.dates.approvedAt),
            },
            {
              Organiser: task.request?.user?.name,
              Organisation: task.request?.user?.organisation,
              Assurances: task.request?.user?.assurances,
              About: task.request?.user?.aboutme,
              Address: [task.request?.user?.street, task.request?.user?.area, task.request?.user?.postcode]
                .filter(Boolean)
                .join(', '),
              Email: task.request?.user?.email,
              Phone: task.request?.user?.phone,
            },
          ]}
        />
      )}
      {task.transport && (
        <PartCard
          title="Transport"
          data={[
            {
              Transporter: task.transport?.user?.name,
              Organisation: task.transport?.user?.organisation,
              Assurances: task.transport?.user?.assurances,
              About: task.transport?.user?.aboutme,
              Address: [task.transport?.user?.street, task.transport?.user?.area, task.transport?.user?.postcode]
                .filter(Boolean)
                .join(', '),
              Email: task.transport?.user?.email,
              Phone: task.transport?.user?.phone,
            },
            {
              Assigned: task.dates.carrierAssignedAt && formatDate(task.dates.carrierAssignedAt),
              Collected: task.dates.markedCollectedAt && formatDate(task.dates.markedCollectedAt),
              Delivered: task.dates.markedDeliveredAt && formatDate(task.dates.markedDeliveredAt),
            },
          ]}
        />
      )}
    </Container>
  );
};

export default TaskDetail;
