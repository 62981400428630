import React from 'react';
import * as route from '../common/util/routeShorthand';
import URLS from './urls';
import ProfilePage from './ProfilePage';
import EditLocationPage from './EditLocationPage';
import EditContactPage from './EditContactPage';
import EditRolesPage from './EditRolesPage';

export const ROUTES = [
  route.loggedIn(URLS.profile, <ProfilePage />),
  route.loggedIn(URLS.profile_edit_contact, <EditContactPage />),
  route.loggedIn(URLS.profile_edit_location, <EditLocationPage />),
  route.loggedIn(URLS.profile_edit_roles, <EditRolesPage />),
];
