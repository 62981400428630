import React, {useCallback, useMemo, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import EditLocationForm from '../common/EditLocationForm';
import {User} from '../types';
import {createCoordinate} from '../common/util/mapUtil';
import withUser from '../common/withUser';
import URLS from './urls';
import {updateUser} from '../api/api.users';
import {useHistory} from 'react-router-dom';
import ErrorFree from '../common/ErrorFree';
import {useDispatch} from 'react-redux';
import {setLoggedUser} from '../redux/user';
import {mapValidationErrors} from '../common/ValidationError';

type PropsType = {
  user: User;
};

const EditLocationPage = (props: PropsType) => {
  const {user} = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});
  const dispatch = useDispatch();
  const history = useHistory();
  const coordinate = useMemo(() => createCoordinate(user.latitude, user.longitude), [user.latitude, user.longitude]);

  const handleOnResult = useCallback(
    async result => {
      setLoading(true);
      setError(null);
      setValidationErrors({});
      try {
        const response = await updateUser({
          ...user,
          latitude: result.coordinate?.latitude,
          longitude: result.coordinate?.longitude,
          street: result.street,
          area: result.area,
          postcode: result.postcode,
        });
        dispatch(setLoggedUser(response.user));
        history.push(URLS.profile, {message: 'Your location and address were updated'});
      } catch (e) {
        setError(e.message);
        setValidationErrors(mapValidationErrors(e?.body?.errors || []));
      } finally {
        setLoading(false);
        window.scrollTo(0, 0);
      }
    },
    [dispatch, history, user]
  );

  return (
    <AppContainer>
      <PageHeader iconName={'previous'} to={URLS.profile} text={'Edit Profile'} />
      <ErrorFree error={error} />
      <EditLocationForm
        className={'limit-width'}
        coordinate={coordinate}
        street={user.street}
        area={user.area}
        postcode={user.postcode}
        buttonText={'Update location'}
        onResult={handleOnResult}
        loading={loading}
        validationErrors={validationErrors}
      />
    </AppContainer>
  );
};

export default withUser(EditLocationPage);
