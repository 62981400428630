import React from 'react';
import {ListGroup} from 'reactstrap';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from '../requests/urls';
import ListButton from '../common/ListButton';
import SimpleGuide from '../common/SimpleGuide';

const RequestsPage = () => {
  return (
    <AppContainer>
      <PageHeader text="Requests" iconName="request" bottomMargin={false} />
      <SimpleGuide
        icon="request"
        text="Do you need PPE? Do you need material to fabricate? Post a Request to let those that can help know what you need."
      />
      <ListGroup flush className={'limit-width'}>
        <ListButton text={'Open Requests'} to={URLS.requests_open} />
        <ListButton text={"Requests I've made"} to={URLS.requests_mine} />
        <ListButton text={'Add Request'} variant={'add'} to={URLS.request_add} />
      </ListGroup>
    </AppContainer>
  );
};

export default React.memo(RequestsPage);
