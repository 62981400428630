import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {bprequesting} from '../images';

const about = `Before Requesting new PPE or material, check the available Offers. Maybe someone has already offered what you need. 
Also, post Requests in small batches, so different makers and suppliers can help you. 
If you are requesting PPE, consider amounts between 20 to 50 units, and if you are requesting material, consider amounts that will assist you in making PPE for the next 5-7 days. 
It is important to be clear in the instructions for delivery. When and where are you available to receive the package? Who will receive it? Should the driver contact you upon arrival?`;

const BestPracticeRequesting: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.bestPractice} />
      <Container className="p-0 limit-width text-center">
        <img src={bprequesting} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">Best Practice for requesting</h1>
        {about.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default BestPracticeRequesting;
