import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {bpoffering} from '../images';

const about = `Before Offering new PPE or material, check the current Requests. 
Maybe someone has already requested what you have. Also, post Offers in small batches, so you can help different makers and health, care or medical institutions continuously. 
If you are offering material, consider amounts that will assist one maker in producing PPE for the next 5-7 days. 
It is important to be clear in the instructions for pick up. When and where will the package be available? Who will hand it in? Should the driver contact you upon arrival?`;

const BestPracticeOffering: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.bestPractice} />
      <Container className="p-0 limit-width text-center">
        <img src={bpoffering} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">Best Practice for offering</h1>
        {about.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default BestPracticeOffering;
