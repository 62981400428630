import React, {useCallback} from 'react';
import './CircleCheckbox.css';
import {Button} from 'reactstrap';

type PropsType = {
  small?: boolean;
  value: number | string;
  text: string;
  image?: string;
  checked: boolean;
  onSelect: (value: number | string) => void;
};

const CircleCheckbox = (props: PropsType) => {
  const {value, text, image, checked, onSelect, small = false} = props;

  const handleClick = useCallback(() => {
    if (!checked) {
      onSelect(value);
    }
  }, [checked, onSelect, value]);

  return (
    <Button
      color="link"
      className={
        'CircleCheckbox d-flex flex-column align-items-center' +
        (checked ? ' checked' : '') +
        (small ? ' small' : ' big')
      }
      active={checked}
      onClick={handleClick}
    >
      <div className={'outer-circle rounded-circle mb'}>
        <div className={'inner-circle rounded-circle shadow d-flex align-items-center justify-content-center'}>
          {image ? <img src={image} alt={text} /> : text}
        </div>
      </div>
      {image !== undefined && <span>{text}</span>}
    </Button>
  );
};

export default CircleCheckbox;
