export type TaskUser = {
  id: UID;
  name?: string;
  email?: string;
  organisation?: string;
  street?: string;
  area?: string;
  postcode?: string;
  phone?: string;
  assurances?: string;
  aboutme?: string;
};

export type ApiTask = {
  id: UID;

  offeringUser: UID | null;
  pickupStreet: string | null;
  pickupArea: string | null;
  pickupPostcode: string | null;
  pickupName: string | null;
  pickupNotes: string | null;
  pickupInterval: string | null;
  pickupLongitude: number | null;
  pickupLatitude: number | null;

  requestingUser: UID | null;
  deliveryStreet: string | null;
  deliveryArea: string | null;
  deliveryPostcode: string | null;
  deliveryName: string | null;
  deliveryNotes: string | null;
  deliveryInterval: string | null;
  deliveryLongitude: number | null;
  deliveryLatitude: number | null;

  carryingUser: UID | null;

  productId: UID;
  amount: number;

  createdAt: Date;
  offeredAt: Date | null;
  requestedAt: Date | null;
  carrierAssignedAt: Date | null;
  markedCollectedAt: Date | null;
  markedDeliveredAt: Date | null;
  approvedAt: Date | null;
};

export type User = {
  id: UID;
  roles: UserRole[];
  email: string;
  name?: string;
  organisation?: string;
  street?: string;
  area?: string;
  postcode?: string;
  longitude?: number;
  latitude?: number;
  phone?: string;
  aboutme?: string;
  privacyAgreed?: boolean;
  termsAgreed?: boolean;
  statusText: UserStatus;
  isAdmin: boolean;
  identityVerifiedAt: string;
} & Partial<SupplierValues> &
  Partial<MakerValues> &
  Partial<HealthcareValues> &
  Partial<TransporterValues>;

export type RoleName = 'supplier' | 'maker' | 'healthcare' | 'transporter';

export function getRoleName(name: string): RoleName | null {
  switch (name) {
    case 'supplier':
      return name;
    case 'maker':
      return name;
    case 'healthcare':
      return name;
    case 'transporter':
      return name;
    default:
      return null;
  }
}

export type UserRole = {
  name: RoleName;
  assuranceId: UID | null;
  assuranceName?: string | null;
};

export type UserStatus = 'disabled' | 'unverified' | 'verified';

export type SupplierValues = {
  workingHours: string;
  contactName: string;
  materials: Material[];
};

export type MakerValues = {
  workingHours: string;
  machines: Machine[];
};

export type Maker = User & MakerValues;

export type HealthcareValues = {
  description: string;
};

export type TransporterValues = {
  vehicle: Vehicle;
  workingHours: string;
};

export type UID = string;

export type MachineModel = {
  id: UID;
  name: string;
};

export type Machine = {
  model: MachineModel;
  units: number;
  materials: string; // TODO: Make it Material[] if needed
};

export type ProductCategory = string;

export class ProductImage {
  src: string;
  constructor(src: string) {
    this.src = src;
  }
}

export type Product = {
  id: UID;
  category: ProductCategory;
  subcategory: string | null;
  name: string;
  // what 1 unit of this product means, e.g. '1kg'.
  unit: string | null;
  image: ProductImage | null;
};

export type Material = Product & {category: 'material'};
export type Intermediate = Product & {category: 'intermediate'};
export type Equipment = Product & {category: 'equipment'};

export type Vehicle = string;
export type Coordinate = {
  longitude: number;
  latitude: number;
};

export type OrderItem = {
  item: Product;
  amount: number;
};

export type Offer = {
  user_id: UID;
  user?: TaskUser;
  pickupStreet?: string | null;
  pickupArea: string;
  pickupPostcode: string;
  pickupName?: string | null;
  pickupNotes?: string | null;
  pickupInterval?: string | null;
  pickupCoordinate?: Coordinate | null;
};
export type OfferToMatchRequest = Omit<Offer, 'user_id'>;

export type Request = {
  user_id: UID;
  user?: TaskUser;
  deliveryStreet?: string | null;
  deliveryArea: string;
  deliveryPostcode: string;
  deliveryName?: string | null;
  deliveryNotes?: string | null;
  deliveryInterval?: string | null;
  deliveryCoordinate?: Coordinate | null;
};
export type RequestToMatchOffer = Omit<Request, 'user_id'>;

export type Task = {
  id: UID;
  offer: null | Offer;
  request: null | Request;
  transport: null | Transport;
  contents: OrderItem;
  dates: TaskDates;
};

export type TaskDates = {
  createdAt: null | Date;
  offeredAt: null | Date;
  requestedAt: null | Date;
  carrierAssignedAt: null | Date;
  approvedAt: null | Date;
  markedCollectedAt: null | Date;
  markedDeliveredAt: null | Date;
};

export type TaskStatus =
  | 'invalid'
  | 'open_offer'
  | 'open_request'
  | 'awaiting_transporter'
  | 'awaiting_approval'
  | 'approved'
  | 'collection_complete'
  | 'delivery_complete';

export type Transport = {
  user_id: UID;
  user?: TaskUser;
};

export type Assurance = {
  id: UID;
  name: string;
  roleId: number;
  role: string;
};
