import React, {FormEvent, useCallback, useState} from 'react';
import {Button, Form, Container, Alert} from 'reactstrap';
import AppContainer from '../common/AppContainer';
import {useHistory, useLocation} from 'react-router-dom';
import LabelledInput from '../common/LabelledInput';
import URLS from '../urls';
import {useDispatch} from 'react-redux';
import {setLoggedUser} from '../redux/user';
import {useInputValueCallback} from '../common/hooks/useInputValueCallback';
import {login} from '../api/api.users';
import ErrorFree from '../common/ErrorFree';
import ConditionalSpinner from '../common/ConditionalSpinner';
import {User} from '../types';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{message?: string}>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleChangeUser = useInputValueCallback(setUser);
  const handleChangePassword = useInputValueCallback(setPassword);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!user || !password) {
        setError('Please provide an email and a password');
        return;
      }

      let loggedUser: User | null = null;
      try {
        setError(null);
        setLoading(true);
        loggedUser = await login(user, password);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
      if (loggedUser) {
        dispatch(setLoggedUser(loggedUser));
        history.push(URLS.dashboard);
      }
    },
    [user, password, dispatch, history]
  );

  return (
    <AppContainer>
      {!!location.state?.message && <Alert color="info">{location.state?.message}</Alert>}
      <Container className="p-4 limit-width">
        <h1>Login to continue</h1>
        <ErrorFree error={error} />
        <Form onSubmit={handleSubmit}>
          <LabelledInput name="user" label="Email" type="email" value={user} onChange={handleChangeUser} />
          <LabelledInput
            name="password"
            label="Password"
            type="password"
            value={password}
            onChange={handleChangePassword}
          />
          <ConditionalSpinner spinner={loading}>
            <Button className="mt-4" type={'submit'} size="lg" block>
              Login
            </Button>
          </ConditionalSpinner>
        </Form>
      </Container>
    </AppContainer>
  );
};

export default LoginPage;
