import React from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from '../urls';
import URLS_REQUESTS from './urls';
import {User} from '../types';
import {findOpenRequests} from '../api/api.tasks';
import TaskList from '../common/TaskList';
import withUser from '../common/withUser';

type PropsType = {
  user: User;
};

const OpenRequestsPage = (props: PropsType) => {
  const {user} = props;

  return (
    <AppContainer>
      <PageHeader text="Open Requests" iconName="previous" to={URLS_REQUESTS.requests} bottomMargin={false} />
      <TaskList
        className={'limit-width'}
        taskProvider={findOpenRequests}
        userId={user.id}
        moreInfo={URLS.task_info}
        noResults={'There are no open requests at the moment.'}
      />
    </AppContainer>
  );
};

export default withUser(OpenRequestsPage);
