import React, {ReactNode} from 'react';
import {Alert} from 'reactstrap';

type PropsType = {
  error: string | null;
  children?: ReactNode;
};

/**
 * If the given error is non-null, display it in an alert; otherwise, display the given children
 */
const ErrorFree = (props: PropsType) => {
  const {error, children} = props;
  return error ? <Alert color={'danger'}>{error.trim() || 'Error'}</Alert> : <>{children}</>;
};

export default ErrorFree;
