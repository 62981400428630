import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const ProfileIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" strokeWidth="1.1">
        <path
          d="m33.1925234 33.8070605-.0761215-.7746485-.1141823-.7746485-.170154-.7746485-.2082148-.7544987-.2462755-.7365878-.3022473-.716438-.340308-.7164379-.3783688-.6716606-.4343405-.6716605-.4477737-.6425553-.4903122-.6044945-.5485228-.5664338-.5664338-.5485228-.5865836-.510462-.6425552-.4477737-.6425553-.4343405-.6716606-.3962798-.698527-.3582189-.7186768-.3022473-.7567376-.2641865-.7365877-.2238868-.7746486-.1701541-.7746485-.1320932-.7746485-.0761215-.7925595-.0380608-.7746485.0179109-.7925595.0559718-.7746485.0940324-.7567376.1500042-.7746485.2082148-.7365878.2462756-.7365877.2820974-.698527.3201582-.698527.3783688-.6716606.4164295-.6425553.4477738-.6224054.4903122-.5865836.510462-.5485228.5664338-.51046204.5843447-.47240127.6224054-.44777371.6425553-.39627974.6716606-.35821897.698527-.3201582.7164379-.28433631.7365878-.22388686.7365877-.18806496.7746486-.13209325.7746485-.09403248.7746485-.05597171.7746485v.3873243h26.2104344z"
          stroke={color}
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <circle cx="20.5" cy="12.909091" r="4.909091" stroke={color} />
      </g>
    </svg>
  );
};
export default ProfileIcon;
