const URLS = {
  offers: '/offers',
  offers_open: '/offers/open',
  offers_mine: '/offers/mine',
  offers_claimed: '/offers/claimed',
  offer_add: '/offers/add',
  offer_add_category: (category = ':category') => `/offers/add/${category}`,
  offer_claim: (taskId = ':taskId') => `/offers/${taskId}/claim`,
};

export default URLS;
