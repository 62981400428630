import React, {useCallback, useMemo} from 'react';
import {Product, ProductImage} from '../types';
import CircleCheckbox from './CircleCheckbox';

type PropsType = {
  products?: Product[];
  selectedProduct?: Product;
  onChange: (product?: Product) => void;
};

const ProductSelector = (props: PropsType) => {
  const {products, selectedProduct, onChange} = props;

  const subcategories = useMemo(() => {
    const result: {[key: string]: {name: string; image: ProductImage | null}} = {};
    if (!products) {
      return [];
    }
    for (const product of products) {
      if (product.subcategory && !result[product.subcategory]) {
        result[product.subcategory] = {
          name: product.subcategory,
          image: product.image,
        };
      }
    }
    return Object.values(result);
  }, [products]);

  const handleSubcategorySelect = useCallback(
    value => {
      onChange(products?.find(p => p.subcategory === value));
    },
    [onChange, products]
  );

  const handleProductSelect = useCallback(
    value => {
      onChange(products?.find(p => p.id === value));
    },
    [onChange, products]
  );

  if (!products) {
    return null;
  }

  return (
    <div className="overflow-auto">
      <div className="d-flex fex-row">
        {/* An option for each subcategory */}
        {subcategories?.map(subcategory => {
          return (
            <CircleCheckbox
              key={`c-${subcategory.name}`}
              value={subcategory.name}
              text={subcategory.name}
              image={subcategory.image?.src || undefined}
              checked={subcategory.name === selectedProduct?.subcategory}
              onSelect={handleSubcategorySelect}
            />
          );
        })}
        {/* An option for each product without subcategory */}
        {products?.map(product => {
          if (product.subcategory) {
            return null;
          }
          return (
            <CircleCheckbox
              key={`p-${product.id}`}
              value={product.id}
              text={product.name}
              image={product.image?.src || undefined}
              checked={product.id === selectedProduct?.id}
              onSelect={handleProductSelect}
            />
          );
        })}
      </div>
      {/* An option for each product of the selected subcategory */}
      {selectedProduct?.subcategory && (
        <div className={'d-flex fex-row mt-4'}>
          {products?.map(product => {
            if (product.subcategory !== selectedProduct.subcategory) {
              return null;
            }
            return (
              <CircleCheckbox
                key={`p-${product.id}`}
                small={true}
                value={product.id}
                text={product.name}
                checked={product.id === selectedProduct?.id}
                onSelect={handleProductSelect}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProductSelector;
