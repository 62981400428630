import React, {FormEvent, useCallback, useEffect} from 'react';
import {Button, Form, Container, Modal} from 'reactstrap';
import roles from './roles';
import RoleSelector from './components/RoleSelector';
import {FC} from 'react';
import {useState} from 'react';
import {IconCircleDropShadow} from '../common/Icon';
import {UserRequest} from '../api/api.users';
import {listAssurances} from '../api/api.users';
import {Assurance, getRoleName, RoleName, UID, UserRole} from '../types';
import ErrorFree from '../common/ErrorFree';
import ConditionalSpinner from '../common/ConditionalSpinner';
import ValidationError from '../common/ValidationError';

const healthcareMessageTitle = 'Healthcare Professionals';
const healthcareMessageText = `To make sure PPE is going directly into the healthcare system, healthcare professionals will be screened by a member of our team before you can make a request.\n\nWe will be in touch with you after signup is finished.`;

const HealthcareMessage: FC = () => {
  return (
    <Container className="d-flex flex-column align-items-center">
      <IconCircleDropShadow name="healthWorker" size={92} />
      <h4 className="py-4">{healthcareMessageTitle}</h4>
      {healthcareMessageText.split('\n').map(t => (
        <p key={t}>{t}</p>
      ))}
    </Container>
  );
};

type RoleMap = {[role in RoleName]?: UID | null}; // roleName -> assuranceId

type Props = {
  index: number;
  initialData: UserRequest;
  onSubmit: (index: number, data: UserRequest) => void;
  validationErrors: {[key: string]: string};
};

const SignupRolesForm: FC<Props> = props => {
  const {index, initialData, onSubmit, validationErrors} = props;
  const [assuranceList, setAssuranceList] = useState<{[role: string]: Assurance[]}>();
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [rolesData, setRolesData] = useState<RoleMap>(rolesToMap(initialData.roles));
  const [showModal, setShowModal] = useState<RoleName>();
  const [showHealthcareMessage, setShowHealthcareMessage] = useState(false);

  const handleRolesChange = useCallback((id: string, checked: boolean) => {
    const role = id as RoleName;
    if (checked) {
      setRolesData(prevData => ({...prevData, [id]: null}));
      setShowModal(role);
    } else {
      setRolesData(prevData => {
        const newData = {...prevData};
        delete newData[role];
        return newData;
      });
    }
  }, []);

  const cancelModal = useCallback(() => {
    setShowModal(undefined);
  }, []);

  const selectAssurance = useCallback((assurance: Assurance | null) => {
    if (assurance) {
      setRolesData(prevData => ({...prevData, [assurance.role]: assurance.id}));
    }
    setShowModal(undefined);
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setSubmitError(null);
      if (Object.keys(rolesData).length === 0) {
        setSubmitError('Please select at least one role');
      } else if ('healthcare' in rolesData && !showHealthcareMessage) {
        setShowHealthcareMessage(true);
      } else {
        const roles = mapToRoles(rolesData);
        onSubmit(index, {roles});
      }
    },
    [rolesData, showHealthcareMessage, index, onSubmit]
  );

  useEffect(() => {
    const doAsync = async () => {
      try {
        setAssuranceList(await listAssurances());
      } catch (e) {
        setFetchError('There was an error. Please reload the page. ' + e.message);
      }
    };
    doAsync();
  }, []);

  return (
    <ErrorFree error={fetchError}>
      <ConditionalSpinner spinner={!assuranceList}>
        <ErrorFree error={submitError} />
        <ValidationError keys={['roles']} errors={validationErrors} />
        <Form className="p-4 d-flex flex-column align-items-stretch" onSubmit={handleSubmit}>
          {showHealthcareMessage ? (
            <HealthcareMessage />
          ) : (
            <>
              {Object.values(roles).map(role => (
                <RoleSelector
                  key={role.id}
                  id={role.id}
                  iconName={role.iconName}
                  label={role.description}
                  onChange={handleRolesChange}
                  checked={role.id in rolesData}
                />
              ))}
              <Modal isOpen={!!showModal} toggle={cancelModal} centered returnFocusAfterClose={false}>
                <Container className={'py-4'}>
                  <h3 className={'mb-3'}>{showModal && roles[showModal].assuranceText}</h3>
                  <ul className={'list-group'}>
                    <li className={'list-group-item d-flex p-0'}>
                      <Button color={'light'} className={'flex-grow-1 p-4'} onClick={() => selectAssurance(null)}>
                        {'None'}
                      </Button>
                    </li>
                    {assuranceList &&
                      showModal &&
                      assuranceList[showModal].map(e => (
                        <li key={e.id} className={'list-group-item d-flex p-0'}>
                          <Button color={'light'} className={'flex-grow-1 p-4'} onClick={() => selectAssurance(e)}>
                            {e.name}
                          </Button>
                        </li>
                      ))}
                  </ul>
                </Container>
              </Modal>
            </>
          )}
          <Button type="submit" size="lg" color="dark" className="mt-4" block>
            Next
          </Button>
        </Form>
      </ConditionalSpinner>
    </ErrorFree>
  );
};

export default SignupRolesForm;

function rolesToMap(roles?: UserRole[]): RoleMap {
  if (!roles) {
    return {};
  }
  return roles.reduce((prev, curr) => ({...prev, [curr.name]: curr.assuranceId}), {});
}

function mapToRoles(map: RoleMap): UserRole[] {
  return Object.entries(map).reduce<UserRole[]>((prev, [key, value]) => {
    const name = getRoleName(key);
    if (!name) {
      return prev;
    }
    const assuranceId = value || null;
    return [...prev, {name, assuranceId}];
  }, []);
}
