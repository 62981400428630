import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const PreviousBasicIcon: FC<Props> = props => {
  const {className, size = 54, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
      <polygon
        fill={color}
        fillRule="nonzero"
        points="18.3846154 7 4 21.5 18.3846154 36 21 33.3636364 9.23139194 21.5001255 21 9.63636364"
      />
    </svg>
  );
};
export default PreviousBasicIcon;
