const URLS = {
  home: '/',
  login: '/login',
  information: '/information',
  about: '/information/about-project',
  how: '/information/how-this-works',
  help: '/information/how-can-i-help',
  bestPractice: '/information/best-practice',
  bestPracticeOffering: '/information/best-practice-offering',
  bestPracticeRequesting: '/information/best-practice-requesting',
  bestPracticeTransporting: '/information/best-practice-transporting',
  bestPracticeCollecting: '/information/best-practice-collecting',
  bestPracticeDelivering: '/information/best-practice-delivering',
  verifyHealthWorkers: '/information/verify-health-workers',
  resources: '/information/resources',
  more: '/information/more-information',
  credits: '/information/credits',
  terms: '/information/terms',
  privacy: '/information/privacy',
  dashboard: '/dashboard',
  wip: '/wip',
  task_info: (taskId = ':taskId') => `/tasks/${taskId}`,
};

export default URLS;
