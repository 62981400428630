import React, {useEffect, useState} from 'react';
import {Task, UID} from '../types';
import ConditionalSpinner from './ConditionalSpinner';
import TaskInfo from './TaskSummary';
import ErrorFree from './ErrorFree';
import {Alert, Container} from 'reactstrap';

type PropsType = {
  /* Used to asynchronously load tasks given a user ID. */
  taskProvider: (userId: UID) => Promise<Task[]>;

  /* ID of the currently logged in user. It will be passed as argument to `taskProvider`. Also used to decide how
  information is presented and what actions are available. */
  userId: UID;

  /* Given a task ID, returns the URL the "More info" button points to.  */
  moreInfo: (taskId: UID) => string;

  /* Message to show if the list returned by `taskProvider` is empty. If not set, a default message is used. */
  noResults?: string;

  className?: string;
};

/**
 * Shows information about different tasks in a list. The actual list of tasks is fetched asynchronously according to
 * the given params.
 */
const TaskList = (props: PropsType) => {
  const {taskProvider, userId, moreInfo, noResults, className} = props;
  const [tasks, setTasks] = useState<Task[]>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const doAsync = async () => {
      try {
        setTasks(await taskProvider(userId));
      } catch (e) {
        setError('Could not fetch tasks from the server. Please reload the page. ' + e.message);
      }
    };
    doAsync();
  }, [taskProvider, userId]);

  return (
    <Container className={['px-3', className].join(' ')}>
      <ErrorFree error={error}>
        <ConditionalSpinner spinner={!tasks}>
          {!tasks?.length ? (
            <Alert color={'info'} className={'mt-3'}>
              {noResults || 'No results.'}
            </Alert>
          ) : (
            <ul className="px-0 py-1 list-unstyled">
              {tasks?.map((task, index) => (
                <li key={index}>
                  <TaskInfo task={task} userId={userId} to={moreInfo(task.id)} />
                </li>
              ))}
            </ul>
          )}
        </ConditionalSpinner>
      </ErrorFree>
    </Container>
  );
};

export default TaskList;
