import React, {FC} from 'react';
import {Task, UID} from '../types';
import {Button} from 'reactstrap';
import {isOpenTask, isUserOwner, isUserInvolved} from './util/taskUtil';

type Props = {
  className?: string;
  task: Task;
  userId: UID;
};

const TaskActionLabel: FC<Props> = props => {
  const {className, task, userId} = props;
  let label = undefined;
  if (isOpenTask(task) && !isUserOwner(task, userId)) label = 'Claim';
  if (isUserInvolved(task, userId)) label = 'Info';
  if (!label) return null;
  return (
    <Button className={className} tag="div" color="dark" size="lg">
      {label}
    </Button>
  );
};

export default React.memo(TaskActionLabel);
