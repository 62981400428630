import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {verify} from '../images';

const about = `If you sign up to request PPE, please note we may ask to verify your request is genuine by email. This is to prevent fraud. Please ensure you check your Spam folder for emails from ppehive@gmail.com. You may be asked to provide the following information:
Name
Role
Your institution/organisation email address
Your work phone number (not your personal mobile)
A photograph of you holding your institution/organisation proof of ID and personal ID (passport or driver’s license)`;

const AboutProjectPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-0 limit-width text-center">
        <img src={verify} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">Verifying Health Workers</h1>
        {about.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default AboutProjectPage;
