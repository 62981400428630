import React from 'react';
import {Card, CardHeader} from 'reactstrap';

type PropsType = {
  title: string;
  data: Array<{[key: string]: string | undefined | null}>;
};

const PartCard = ({title, data}: PropsType) => (
  <Card className="mb-3">
    <CardHeader>
      <h5 className="m-0">{title}</h5>
    </CardHeader>
    <ul className="list-group list-group-flush">
      {data
        .filter(g => Object.values(g).filter(Boolean).length)
        .map(g => (
          <li className="list-group-item" key={Object.values(g).join('')}>
            <dl className="row p-0 m-0">
              {Object.keys(g)
                .filter(label => Boolean(g[label]))
                .map(label => (
                  <React.Fragment key={label}>
                    <dt className="col-sm-3">{label}</dt>
                    <dd className="col-sm-9 m-0">{g[label] || <small className="text-muted">N/A</small>}</dd>
                  </React.Fragment>
                ))}
            </dl>
          </li>
        ))}
    </ul>
  </Card>
);

export default PartCard;
