import React, {CSSProperties, useCallback, useMemo} from 'react';
import {Coordinate} from '../types';
import {GoogleMap, Marker, Polyline, useLoadScript} from '@react-google-maps/api';
import {markerBlack, markerWhite} from '../images';
import {coordinateToLatLngLiteral, coordinateToString, createIcon} from './util/mapUtil';
import withGoogleMapsApiKey from './withGoogleMapsApiKey';

function serializeQuery(obj: {[key: string]: string}): string {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

const mapOptions: google.maps.MapOptions = {
  zoomControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  gestureHandling: 'none',
};

// Define a symbol using SVG path notation, with an opacity of 1.
const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 2,
};

const lineOptions: google.maps.PolylineOptions = {
  strokeOpacity: 0,
  icons: [
    {
      icon: lineSymbol,
      offset: '0',
      repeat: '10px',
    },
  ],
};

type PropsType = {
  googleMapsApiKey: string;
  fromCoordinate?: Coordinate | null;
  toCoordinate?: Coordinate | null;
  openButton?: boolean;
  style?: CSSProperties;
};

/**
 * Display a map with source and/or destination locations of the given task. If both locations are present, a
 * connecting line is also shown. If none of the locations are present, this component is not displayed at all.
 */
const TaskMap = (props: PropsType) => {
  const {googleMapsApiKey, fromCoordinate, toCoordinate, openButton = false, style} = props;
  const {isLoaded: isGoogleLoaded} = useLoadScript({googleMapsApiKey});

  const fromLatLng = useMemo(() => coordinateToLatLngLiteral(fromCoordinate), [fromCoordinate]);
  const toLatLng = useMemo(() => coordinateToLatLngLiteral(toCoordinate), [toCoordinate]);
  const whiteMarkerIcon = useMemo(() => (isGoogleLoaded ? createIcon(markerWhite, 20) : undefined), [isGoogleLoaded]);
  const blackMarkerIcon = useMemo(() => (isGoogleLoaded ? createIcon(markerBlack, 20) : undefined), [isGoogleLoaded]);

  const handleMapLoad = useCallback(
    (map: google.maps.Map) => {
      if (fromLatLng && toLatLng) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(fromLatLng);
        bounds.extend(toLatLng);
        map.fitBounds(bounds, {bottom: 25, left: 0, right: 0, top: 0});
      }
    },
    [fromLatLng, toLatLng]
  );

  if (!fromLatLng && !toLatLng) {
    return null;
  }

  const query: {[key: string]: string} = {api: '1'};
  if (!fromCoordinate && toCoordinate) {
    query.destination = coordinateToString(toCoordinate) || '';
  } else if (fromCoordinate && !toCoordinate) {
    query.destination = coordinateToString(fromCoordinate) || '';
  } else if (fromCoordinate && toCoordinate) {
    query.waypoints = coordinateToString(fromCoordinate) || '';
    query.destination = coordinateToString(toCoordinate) || '';
  }
  const directionsQueryString = serializeQuery(query);
  const directionsURL = `https://www.google.com/maps/dir/?${directionsQueryString}`;

  return (
    <div className={'position-relative d-flex align-items-end justify-content-end'} style={{height: 200, ...style}}>
      {isGoogleLoaded && (
        <>
          <GoogleMap
            mapContainerClassName={'absolute-fill'}
            zoom={12}
            center={fromLatLng || toLatLng || undefined}
            options={mapOptions}
            onLoad={handleMapLoad}
          >
            {fromLatLng && <Marker position={fromLatLng} icon={whiteMarkerIcon} />}
            {toLatLng && <Marker position={toLatLng} icon={blackMarkerIcon} />}
            {fromLatLng && toLatLng && <Polyline path={[fromLatLng, toLatLng]} options={lineOptions} />}
          </GoogleMap>
          {openButton && (
            <a
              className={'btn btn-dark position-relative m-3'}
              href={directionsURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open route in Google Maps
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default withGoogleMapsApiKey(TaskMap);
