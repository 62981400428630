import React, {useEffect, useState} from 'react';
import {ListGroup} from 'reactstrap';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import {getConfig} from '../api/api.common';
import ListButton from '../common/ListButton';
import URLS from '../urls';

const InformationPage = () => {
  const [footnote, setFootnote] = useState<string | null>(null);
  useEffect(() => {
    const doAsync = () => getConfig().then(config => setFootnote(`${config.serverName} ${config.version}`));
    doAsync();
  });
  return (
    <AppContainer>
      <BrandHeader iconName="healthWorker" />
      <ListGroup flush className={'limit-width'}>
        <ListButton text={'How this works'} to={URLS.how} />
        <ListButton text={'About'} to={URLS.about} />
        <ListButton text={'How can I help'} to={URLS.help} />
        <ListButton text={'Best Practice'} to={URLS.bestPractice} />
        <ListButton text={'Verifying Health Workers'} to={URLS.verifyHealthWorkers} />
        <ListButton text={'Resources'} to={URLS.resources} />
        <ListButton text={'Credits'} to={URLS.credits} />
        <ListButton text={'Terms & Conditions'} to={URLS.terms} />
        <ListButton text={'Privacy Policy'} to={URLS.privacy} />
      </ListGroup>
      {footnote && <p className="my-3 limit-width">{footnote}</p>}
    </AppContainer>
  );
};

export default InformationPage;
