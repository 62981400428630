import React, {ReactNode, useCallback, useRef, useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Row, Spinner} from 'reactstrap';
import LocationPicker from './LocationPicker';
import {useInputValueCallback} from './hooks/useInputValueCallback';
import {Coordinate} from '../types';
import {Address, coordinateToLatLngLiteral, latLngLiteralToCoordinate, reverseGeocode} from './util/mapUtil';
import debounce from 'lodash.debounce';
import Icon from './Icon';
import ConditionalSpinner from './ConditionalSpinner';
import ValidationError from './ValidationError';

type PropsType = {
  coordinate?: Coordinate;
  street?: string;
  area?: string;
  postcode?: string;
  onResult: (result: {coordinate?: Coordinate; street: string; area: string; postcode: string}) => void;
  locationHeader?: ReactNode;
  addressHeader?: ReactNode;
  buttonText?: string;
  loading?: boolean;
  validationErrors?: {[key: string]: string};
  className?: string;
};

const EditLocationForm = (props: PropsType) => {
  const {
    coordinate,
    street,
    area,
    postcode,
    onResult,
    locationHeader,
    addressHeader,
    buttonText,
    loading = false,
    validationErrors,
    className,
  } = props;
  const [newLatLng, setNewLatLgn] = useState<google.maps.LatLngLiteral | null>(coordinateToLatLngLiteral(coordinate));
  const [newAddress, setNewAddress] = useState<Address | null>({street, area, postcode} || '');
  const [searchingAddress, setSearchingAddress] = useState(false);
  const [needsReload, setNeedsReload] = useState(false);
  const [lastRevGeoResult, setLastRevGeoResult] = useState<Address>();

  const textAreaRef = useRef<HTMLInputElement>(null);

  const fetchReverseGeocode = useCallback(async (latLng: google.maps.LatLngLiteral) => {
    try {
      setSearchingAddress(true);
      const result = await reverseGeocode(latLng);
      setSearchingAddress(false);
      const firstResult = result[0];
      if (firstResult) {
        setLastRevGeoResult(firstResult);
        setNewAddress(firstResult);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const debouncedReverseGeocode = useCallback(debounce(fetchReverseGeocode, 1000), []);

  /*
   * When map location changes, compare current textarea content with the latest reverse geocode result. If it's the
   * same, fire a new reverse geocode request. If it's different (the user has manually changed it), just show the
   * reload button so the user can manually fire a new reverse geocode request that would replace textarea content.
   * */
  const handleLocationChange = useCallback(
    (latLng: google.maps.LatLngLiteral) => {
      setNewLatLgn(latLng);
      if (!newAddress || !lastRevGeoResult || JSON.stringify(newAddress) === JSON.stringify(lastRevGeoResult)) {
        setNeedsReload(false);
        debouncedReverseGeocode(latLng);
      } else {
        setNeedsReload(true);
      }
    },
    [newAddress, lastRevGeoResult, debouncedReverseGeocode]
  );

  const handleStreetChange = useInputValueCallback(inputVal => {
    setNewAddress(address => ({...address, street: inputVal}));
  });

  const handleAreaChange = useInputValueCallback(inputVal => {
    setNewAddress(adress => ({...adress, area: inputVal}));
  });

  const handlePostcodeChange = useInputValueCallback(inputVal => {
    setNewAddress(address => ({...address, postcode: inputVal}));
  });

  const handleClickReload = useCallback(() => {
    setNeedsReload(false);
    if (newLatLng) {
      fetchReverseGeocode(newLatLng);
    }
  }, [newLatLng, fetchReverseGeocode]);

  const handleClickConfirm = useCallback(() => {
    const coordinate = latLngLiteralToCoordinate(newLatLng) || undefined;
    onResult({
      coordinate,
      street: newAddress?.street || '',
      area: newAddress?.area || '',
      postcode: newAddress?.postcode || '',
    });
  }, [newLatLng, newAddress, onResult]);

  return (
    <div className={['EditLocationForm d-flex flex-column', className].join(' ')}>
      {locationHeader || <h2 className={'mx-3'}>Choose location</h2>}
      <p className={'mx-3'}>Drag the map to find the best place to show people on the map.</p>
      <LocationPicker coordinate={coordinate} onChange={handleLocationChange} className={'mb-3'} />
      {newLatLng && (
        <>
          <Row>
            <Col>
              {addressHeader || <h2 className={'mx-3'}>Confirm address</h2>}
              <p className={'mx-3'}>The exact address where people can pick up or deliver stuff.</p>
            </Col>
            {needsReload && (
              <Col className="col-2">
                <Button color={'dark'} className={'textarea-reload p-1'} onClick={handleClickReload}>
                  <Icon name={'reload'} color={'white'} size={28} />
                </Button>
              </Col>
            )}
          </Row>
          <div className={'textarea-container position-relative mx-3'}>
            <FormGroup>
              <Label for="street">Street</Label>
              <Input
                innerRef={textAreaRef}
                id="street"
                type={'text'}
                bsSize="lg"
                name={'street'}
                value={newAddress?.street || ''}
                onChange={handleStreetChange}
              />
              {validationErrors && <ValidationError keys={['street']} errors={validationErrors} />}
            </FormGroup>
            <FormGroup>
              <Label for="area">Town/City</Label>
              <Input
                innerRef={textAreaRef}
                id="area"
                type={'text'}
                bsSize="lg"
                name={'area'}
                value={newAddress?.area || ''}
                onChange={handleAreaChange}
              />
              {validationErrors && <ValidationError keys={['area']} errors={validationErrors} />}
            </FormGroup>
            <FormGroup>
              <Label for="postcode">Postcode</Label>
              <Input
                innerRef={textAreaRef}
                id="postcode"
                type={'text'}
                bsSize="lg"
                name={'postcode'}
                value={newAddress?.postcode || ''}
                onChange={handlePostcodeChange}
              />
              {validationErrors && <ValidationError keys={['postcode']} errors={validationErrors} />}
            </FormGroup>
            {searchingAddress && <Spinner size={'md'} />}
          </div>
          <ConditionalSpinner spinner={loading}>
            <Button
              color={'dark'}
              className={'m-4'}
              onClick={handleClickConfirm}
              disabled={!newLatLng || !newAddress || searchingAddress}
              size={'lg'}
            >
              {buttonText || 'Confirm Address'}
            </Button>
          </ConditionalSpinner>
        </>
      )}
    </div>
  );
};

export default EditLocationForm;
