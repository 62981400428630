import React, {useCallback, useEffect, useMemo, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {useParams} from 'react-router-dom';
// @ts-ignore There is no type definition for this module
import confirm from 'reactstrap-confirm';
import {User, UserRole} from '../types';
import ConditionalSpinner from '../common/ConditionalSpinner';
import ErrorFree from '../common/ErrorFree';
import {getUser, unverifyUser, verifyUser} from '../api/api.users';
import {Alert, Button, Card, CardHeader, Container} from 'reactstrap';
import TaskMap from '../common/TaskMap';
import moment from 'moment';

const UserPage = () => {
  const {userId} = useParams<{userId: string}>();
  const [user, setUser] = useState<User>();
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const Empty = useMemo(() => <span className={'text-muted'}>{'<no data>'}</span>, []);

  const Row = (props: {name: string; value?: string}) => (
    <>
      <dt>{props.name}</dt>
      <dd className={'pre-wrap'}>{props.value || Empty}</dd>
    </>
  );

  const RoleRow = (props: {name: string; roles?: UserRole[]}) => (
    <>
      <dt>{props.name}</dt>
      <dd>
        {props.roles?.length ? (
          <ul>
            {/*TODO Print assurance text*/}
            {props.roles?.map(role => (
              <li key={role.name}>
                {`${role.name} (assurance:`} {role.assuranceName || role.assuranceId || Empty})
              </li>
            ))}
          </ul>
        ) : (
          Empty
        )}
      </dd>
    </>
  );

  const doVerify = useCallback(async (u: User, verify: boolean) => {
    if (!u) {
      return;
    }
    try {
      setVerifying(true);
      setError(null);
      const response = verify ? await verifyUser(u.id) : await unverifyUser(u.id);
      if (!response.user) {
        window.scrollTo(0, 0);
        setError('User not found');
      } else {
        setUser(response.user);
      }
    } catch (e) {
      window.scrollTo(0, 0);
      setError('Could not update user verification. ' + e.message);
    } finally {
      setVerifying(false);
    }
  }, []);

  const handleVerify = useCallback(async () => {
    if (!user) {
      return;
    }
    const confirmed = await confirm({
      title: 'Verify this user?',
      message: 'If the user is a healthcare provider, they will be allowed to request PPE.',
      confirmText: 'Verify',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (confirmed) {
      doVerify(user, true);
    }
  }, [user, doVerify]);

  const handleUnverify = useCallback(async () => {
    if (!user) {
      return;
    }
    const confirmed = await confirm({
      title: 'Remove verification?',
      message: 'The user will not be allowed to request PPE.',
      confirmText: 'Remove verification',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (confirmed) {
      doVerify(user, false);
    }
  }, [user, doVerify]);

  useEffect(() => {
    if (userId === undefined) {
      setFetchError('User ID is required');
      return;
    }

    const doAsync = async () => {
      try {
        const {user} = await getUser(userId);
        if (!user) {
          setFetchError(`User with ID ${user} not found`);
        } else {
          setUser(user);
        }
      } catch (e) {
        setFetchError('Could not fetch user information from the server. ' + e.message);
      }
    };
    doAsync();
  }, [userId]);

  return (
    <AppContainer>
      <PageHeader iconName={'profile'} text={user?.name || 'User'} bottomMargin={false} />
      <ErrorFree error={error} />
      <ErrorFree error={fetchError}>
        <ConditionalSpinner spinner={!user}>
          {user?.latitude && user.longitude && (
            <TaskMap fromCoordinate={{latitude: user?.latitude, longitude: user?.longitude}} />
          )}
          <Container className={'limit-width d-flex flex-wrap align-items-start'}>
            <Card className={'m-3 p-0 flex-shrink-1'} style={{flexGrow: 4, flexBasis: '50%'}}>
              <CardHeader>User data</CardHeader>
              <dl className={'m-3'}>
                <Row name={'Name'} value={user?.name} />
                <Row name={'Email'} value={user?.email} />
                <Row name={'Organisation'} value={user?.organisation} />
                <Row name={'About'} value={user?.aboutme} />
                <Row name={'Phone'} value={user?.phone} />
                <Row name={'Street'} value={user?.street} />
                <Row name={'Area'} value={user?.area} />
                <Row name={'Postcode'} value={user?.postcode} />
                <RoleRow name={'Roles'} roles={user?.roles} />
              </dl>
            </Card>
            <Card className={'m-3 p-0 flex-grow-1'}>
              {user?.identityVerifiedAt ? (
                <>
                  <Alert color={'success'}>
                    User identity verified on {moment(user.identityVerifiedAt).format('DD MMM YYYY')}
                  </Alert>
                  <ConditionalSpinner spinner={verifying}>
                    <Button color={'dark'} className={'mx-3 mb-3'} onClick={handleUnverify}>
                      Remove verification
                    </Button>
                  </ConditionalSpinner>
                </>
              ) : (
                <>
                  <Alert color={'warning'}>User identity not verified</Alert>
                  <ConditionalSpinner spinner={verifying}>
                    <Button color={'dark'} className={'mx-3 mb-3'} onClick={handleVerify}>
                      Verify user
                    </Button>
                  </ConditionalSpinner>
                </>
              )}
            </Card>
          </Container>
        </ConditionalSpinner>
      </ErrorFree>
    </AppContainer>
  );
};

export default UserPage;
