import {useSelector} from 'react-redux';

import userReducer, {NAME as userName} from './user';
import appReducer, {NAME as appName} from './app';

/**
 * Combines all reducers use throughout the application. When new reducers are added, put them here.
 */
const rootReducer = {
  [userName]: userReducer,
  [appName]: appReducer,
} as const;

export default rootReducer;

export type GlobalState = {
  readonly [P in keyof typeof rootReducer]: ReturnType<typeof rootReducer[P]>;
};

export function useOurSelector<T>(sel: (state: GlobalState) => T) {
  return useSelector<GlobalState, T>(sel);
}
