export const NAME = 'app';

// ---------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------
enum ActionTypes {
  SET_SYSTEM_NAME = 'app/SET_SYSTEM_NAME',
}

// ---------------------------------------------------------------
// ACTION CREATORS
// ---------------------------------------------------------------
export function setSystemName(payload: string) {
  return {type: ActionTypes.SET_SYSTEM_NAME, payload};
}

// ---------------------------------------------------------------
// REDUCER
// ---------------------------------------------------------------
export interface StateType {
  systemName: string;
}

const initialState: StateType = {
  systemName: 'Loading',
};

function reducer(state: StateType = initialState, action: any): StateType {
  switch (action.type) {
    case ActionTypes.SET_SYSTEM_NAME:
      return {
        ...state,
        systemName: action.payload,
      };
    default:
      return state;
  }
}

// ---------------------------------------------------------------
// SELECTORS
// ---------------------------------------------------------------
export interface GlobalState {
  [NAME]: StateType;
}

export const getSystemName = (state: GlobalState): string => state[NAME].systemName;

export default reducer;
