import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {ListGroup, Container} from 'reactstrap';

const items = [
  {
    name: 'Prusa’s Face Shield',
    link: 'https://www.prusaprinters.org/prints/25857-prusa-face-shield/files',
    description:
      'Face shield designed by Prusa, consisting of a 3D printed support, to which is attached a protective plastic visor and held in place by an elastic band. Files and fabrication instructions available on their website.',
  },
  {
    name: '3D Verkstan’s Face Shield',
    link: 'https://3dverkstan.se/protective-visor/',
    description:
      'Face shield designed by 3D Verkstan, consisting of a 3D printed support for a clear protective plastic visor, held in place without the need for elastic bands. Files and fabrication instructions available on their website.',
  },
  {
    name: 'Protohaven’s Proto Shield',
    link: 'https://www.protohaven.org/proto-shield/',
    description:
      'Face shield designed by Protohave, designed based on Prusa’s Face Shield, but uses only laser cut parts and elastic bands. Files and fabrication instructions available on their website.',
  },
  {
    name: 'Maker Mask',
    link: 'https://makermask.org/',
    description:
      'Face mask developed by MakerMask, designed to be easily sewn. 3 designs available. Files and fabrication instructions available on their website.',
  },
  {
    name: 'OSCMS Guide',
    link: 'https://osms.li/guide',
    description:
      'Guide developed by Open Source COVID19 Medical Supplies - OSCMS, containing information about the pandemic, guidelines and other resources.',
  },
];

const Resources: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="py-5 px-4 border-bottom limit-width">
        <h1>Resources</h1>
        {items.map(item => (
          <ListGroup className="border-bottom mt-4" flush key={item.name}>
            <h3>{item.name}</h3>
            <p>{item.description}</p>
            <a
              className="button-block btn-dark btn-block btn-lg text-center"
              href={item.link}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              View
            </a>
          </ListGroup>
        ))}
      </Container>
    </AppContainer>
  );
};

export default Resources;
