import React, {useCallback, FC} from 'react';
import Icon from './Icon';
import './CheckBox.css';
import {Input} from 'reactstrap';

type Props = {
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

const CheckBox: FC<Props> = props => {
  const {id, onChange, checked = false} = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    [onChange]
  );

  return (
    <>
      <Input id={id} type="checkbox" checked={checked} onChange={handleChange} hidden />
      <div className="CheckBox flex-shrink-0">{checked && <Icon name="check" />}</div>
    </>
  );
};

export default CheckBox;
