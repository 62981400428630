import React, {FC} from 'react';
import icons, {IconName} from '../icons';
import './Icon.css';

type Props = {
  className?: string;
  name: IconName;
  size?: number;
  color?: string;
};

const Icon: FC<Props> = props => {
  const {className, name, size = 32, color = 'black'} = props;
  const IconComponent = icons[name];
  return <IconComponent className={className} size={size} color={color} />;
};

export const IconCircleDropShadow: FC<Props> = props => {
  const {size = 42} = props;
  const containerSize = size * 1.5;
  const sizeStyle = {
    width: containerSize,
    height: containerSize,
    minWidth: containerSize,
    minHeight: containerSize,
  };
  return (
    <div className="circle-drop-shadow" style={sizeStyle}>
      <Icon {...props} size={size} />
    </div>
  );
};

export default Icon;
