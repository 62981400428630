import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {credits} from '../images';

const text = `Concept & design by Automated Architecture Ltd (AUAR) - automatedarchitecture.io

Mollie Claypool, Director
David Doria, Designer
Manuel Jimenez Garcia, Director

App design & engineering by Calvium - calvium.com
Legal advice from Steer & Co. - steerandco.com
PR Support from Aro PR & Marketing - aroprandmarketing.co.uk`;

const CreditsPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-0 limit-width text-center">
        <img src={credits} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">Credits</h1>
        {text.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default CreditsPage;
