import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const CrosshairIcon: FC<Props> = props => {
  const {className, size = 54, color = 'black'} = props;
  return (
    <svg
      className={className}
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 355.821 355.828"
      enableBackground="new 0 0 355.821 355.828"
    >
      <path
        fill={color}
        d="M312.618,168.485c-4.633-67.065-58.205-120.647-125.281-125.281V0h-18.851v43.204  C101.414,47.838,47.837,101.42,43.205,168.485H0v18.857h43.205c4.633,67.076,58.209,120.647,125.281,125.28v43.205h18.851v-43.205  c67.076-4.633,120.648-58.204,125.281-125.28h43.203v-18.857H312.618z M260.065,260.061c-18.973,18.973-44.385,31.378-72.729,33.659  v-52.783h-18.851v52.783c-28.338-2.281-53.75-14.687-72.724-33.659c-18.956-18.962-31.372-44.375-33.654-72.718h52.79v-18.857  h-52.79c2.282-28.338,14.699-53.745,33.654-72.724c18.973-18.955,44.386-31.366,72.724-33.643v52.778h18.851V62.119  c28.344,2.276,53.756,14.688,72.729,33.643c18.949,18.979,31.379,44.386,33.648,72.724h-52.771v18.857h52.771  C291.444,215.686,279.014,241.099,260.065,260.061z"
      />
    </svg>
  );
};
export default CrosshairIcon;
