import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const PreviousIcon: FC<Props> = props => {
  const {className, size = 54, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
      <filter id="a" height="183.3%" width="183.3%" x="-41.7%" y="-41.7%">
        <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          type="matrix"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(6 3)">
        <circle cx="21" cy="21" fill={color} r="21" />
        <path
          d="m22 4v15h-15"
          stroke="#fff"
          strokeWidth="3"
          transform="matrix(-.70710678 -.70710678 -.70710678 .70710678 42.884776 23.62132)"
        />
      </g>
    </svg>
  );
};
export default PreviousIcon;
