import {createStore} from 'redux';
import {persistStore, persistCombineReducers} from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: ['user'], // add module names here
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);

export default store;
export {persistor};
