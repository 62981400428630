import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const CheckIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.2274568,9.20334182 L33.4568962,10.8973312 L17.6641791,31.6819385 C17.1395622,32.3723806 16.1312552,32.417785 15.5443486,31.8097854 L15.4505879,31.7024006 L8.53848985,22.9463364 L10.7362329,21.2114227 L16.5274667,28.5488 L31.2274568,9.20334182 Z"
        fill={color}
        fillRule="nonzero"
      ></path>
    </svg>
  );
};
export default CheckIcon;
