import {Coordinate} from '../../types';
import Geocode from 'react-geocode';

export function createIcon(image: string, size: number): google.maps.Icon | undefined {
  return {
    url: image,
    scaledSize: new google.maps.Size(size, size),
    anchor: new google.maps.Point(size / 2, size / 2),
  };
}

export function coordinateToLatLngLiteral(coordinate?: Coordinate | null): google.maps.LatLngLiteral | null {
  if (!coordinate) {
    return null;
  }
  return {
    lat: coordinate.latitude,
    lng: coordinate.longitude,
  };
}

export function coordinateToString(coordinate?: Coordinate | null): string | null {
  if (!coordinate) {
    return null;
  }
  return `${coordinate.latitude},${coordinate.longitude}`;
}

export function latLngLiteralToCoordinate(latLng?: google.maps.LatLngLiteral | null): Coordinate | null {
  if (!latLng) {
    return null;
  }
  return {
    latitude: latLng.lat,
    longitude: latLng.lng,
  };
}

export function createCoordinate(latitude?: number, longitude?: number): Coordinate | undefined {
  if (latitude === undefined || latitude === null || longitude === undefined || longitude === null) {
    return undefined;
  }
  return {latitude, longitude};
}

export function fitToLocations(
  map: google.maps.Map,
  latLngs: Array<google.maps.LatLng | google.maps.LatLngLiteral>,
  padding?: number | google.maps.Padding
) {
  const bounds = new google.maps.LatLngBounds();
  for (const latLng of latLngs) {
    bounds.extend(latLng);
  }
  map.fitBounds(bounds, padding);
}

type GeocoderResponse = {
  results: google.maps.GeocoderResult[];
  status: string;
};

export async function geocode(searchString: string): Promise<google.maps.LatLngLiteral[]> {
  try {
    const response: GeocoderResponse = await Geocode.fromAddress(searchString);
    return response.results.slice(0, 5).map(r => {
      const latLng = (r.geometry.location as unknown) as google.maps.LatLngLiteral; // Needs this to get the right type
      return {lat: latLng.lat, lng: latLng.lng};
    });
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getStreet = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const number = addressComponents.find(ac => ac.types.includes('street_number'));
  const route = addressComponents.find(ac => ac.types.includes('route'));
  return [number?.long_name, route?.long_name].filter(Boolean).join(' ');
};

const getArea = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const addressComponent = addressComponents.find(ac => ac.types.includes('postal_town'));
  return addressComponent?.long_name;
};

const getPostcode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const addressComponent = addressComponents.find(ac => ac.types.includes('postal_code'));
  return addressComponent?.long_name;
};

export type Address = {street?: string; area?: string; postcode?: string};

export function formatAddress(address: Address) {
  return [address.street, address.area, address.postcode].join('\n');
}

export async function reverseGeocode(latLng: google.maps.LatLngLiteral): Promise<Array<Address>> {
  const response: GeocoderResponse = await Geocode.fromLatLng(latLng.lat.toString(), latLng.lng.toString());
  return response.results
    .map(r => ({
      street: getStreet(r.address_components) || '',
      area: getArea(r.address_components) || '',
      postcode: getPostcode(r.address_components) || '',
    }))
    .filter(r => r.street.length && r.area.length && r.postcode.length)
    .slice(0, 5);
}
