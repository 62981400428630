import React from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from './urls';
import {ListGroup} from 'reactstrap';
import ListButton from '../common/ListButton';
import SimpleGuide from '../common/SimpleGuide';

const OffersPage = () => {
  return (
    <AppContainer>
      <PageHeader text="Offers" iconName="offer" bottomMargin={false} />
      <SimpleGuide
        icon="offer"
        text="Can you help by providing PPE, making it, or supplying the materials that makers need to fabricate it?"
      />
      <ListGroup flush className={'limit-width'}>
        <ListButton text="Open Offers" to={URLS.offers_open} />
        <ListButton text="Offers I've made" to={URLS.offers_mine} />
        <ListButton text="Add Offer" variant="add" to={URLS.offer_add} />
      </ListGroup>
    </AppContainer>
  );
};

export default React.memo(OffersPage);
