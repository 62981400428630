import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';

const text = `This PPE Hive application is an interface between Health institutions and professionals who are fighting the COVID-19 pandemic in the UK and people and organizations able to help.
As health professionals face a shortage of Personal Protection Equipment (PPE), due to the overwhelming demand, they also risk contamination. For that reason, makers around the world are working to provide 3D printed and/or laser cut PPE to keep them safe and the health system operational. To assist that help, PPE Hive should facilitate the logistic operations between those who need help, those who can make, those who can sponsor and those who can transport.
The parties involved in developing this application have provided their services and facilities free of charge. The Makers, Suppliers, and Transporters using this PPE Hive platform are also providing their services, materials and facilities free of charge and on an “as is” basis. The PPE Hive platform has been created rapidly to respond to the urgent need for PPE; it may have bugs and issues and is provided on an “as is” basis. Please report any bugs or user issues to ppehive@gmail.com as we are trying to improve the operation of the PPE Hive platform along this journey. None of the parties involved in the design, development, hosting, facilities, services or PPE created via this PPE Hive platform shall be liable for any losses, costs or damages arising as a result of your use of the PPE Hive platform or of any PPE provided.
We may stop providing the PPE Hive platform for any reason, and at any time.`;

const TermsAndConditionsPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-4 limit-width">
        <h1 className="py-3">Terms</h1>
        {text.split('\n').map(t => (
          <p>{t}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default TermsAndConditionsPage;
