import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const LocationIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="m21 0c8.2842712 0 15 6.76390246 15 15.107599 0 8.3436965-15 25.892401-15 25.892401s-15-17.5487045-15-25.892401c0-8.34369654 6.7157288-15.107599 15-15.107599zm0 9.06455939c-3.3137085 0-6 2.70556101-6 6.04303961s2.6862915 6.0430396 6 6.0430396 6-2.705561 6-6.0430396-2.6862915-6.04303961-6-6.04303961z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default LocationIcon;
