import React from 'react';
import AppContainer from './AppContainer';
import PageHeader from './PageHeader';
import EditLocationForm from './EditLocationForm';
import {Coordinate} from '../types';

type PropsType = {
  coordinate?: Coordinate;
  street?: string;
  area?: string;
  postcode?: string;
  onResult: (result: {coordinate?: Coordinate; street: string; area: string; postcode: string}) => void;
  onCancel: () => void;
};

const EditLocationModal = (props: PropsType) => {
  const {coordinate, street, area, postcode, onResult, onCancel} = props;
  return (
    <AppContainer>
      <PageHeader text="Edit location" iconName="cross" to={onCancel} />
      <EditLocationForm coordinate={coordinate} street={street} area={area} postcode={postcode} onResult={onResult} />
    </AppContainer>
  );
};

export default EditLocationModal;
