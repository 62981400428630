import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const NextBasicIcon: FC<Props> = props => {
  const {className, size = 54, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
      <polygon
        fill={color}
        fillRule="nonzero"
        points="23.6153846 7 38 21.5 23.6153846 36 21 33.3636364 32.7689402 21.4997908 21 9.63636364"
      />
    </svg>
  );
};
export default NextBasicIcon;
