import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {useParams, useHistory} from 'react-router-dom';
import {Offer, Request, OrderItem, Product, ProductCategory, User, Coordinate} from '../types';
import {Button, Form, FormGroup, FormText, Input, Label, Modal} from 'reactstrap';
import {plus} from '../images';
import URLS from '../urls';
import URLS_OFFERS from '../offers/urls';
import URLS_REQUESTS from '../requests/urls';
import {listProducts} from '../api/api.products';
import {useInputValueCallback} from '../common/hooks/useInputValueCallback';
import ErrorFree from '../common/ErrorFree';
import ConditionalSpinner from '../common/ConditionalSpinner';
import {NBSP, SEP} from '../common/util/chars';
import ProductSelector from '../common/ProductSelector';
import {createOffer, createRequest} from '../api/api.tasks';
import SideButtonsInput from '../common/SideButtonsInput';
import TaskMap from '../common/TaskMap';
import {Address, createCoordinate, formatAddress} from '../common/util/mapUtil';
import withUser from '../common/withUser';
import EditLocationModal from '../common/EditLocationModal';
import ValidationError, {mapValidationErrors} from '../common/ValidationError';

const NAMES: {[key in ProductCategory]: string} = {
  material: 'material',
  equipment: 'PPE',
  intermediate: 'intermediate',
};

type PropsType = {
  action: 'offer' | 'request';
  user: User;
};

const CreateOrderPage = (props: PropsType) => {
  const {action, user} = props;
  const {category: categoryParam} = useParams();
  const history = useHistory();
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});
  const [products, setProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [amount, setAmount] = useState(1);
  const [address, setAddress] = useState<Address>({street: user.street, area: user.area, postcode: user.postcode});
  const [coordinate, setCoordinate] = useState<Coordinate | undefined>(createCoordinate(user.latitude, user.longitude));
  const [contact, setContact] = useState<string>(user.name || '');
  const [notes, setNotes] = useState<string>('');
  const [intervals, setIntervals] = useState<string[]>(['']);
  const [showLocationModal, setShowLocationModal] = useState(false);

  const isOffer = action === 'offer';

  let category: ProductCategory | null = null;
  if (categoryParam && ['material', 'equipment'].includes(categoryParam)) {
    category = categoryParam as ProductCategory; // Force type
  }

  useEffect(() => {
    if (category === null) {
      setFetchError('Unknown category');
      return;
    }

    const doAsync = async () => {
      try {
        setFetchError(null);
        const products = (await listProducts()).filter(p => p.category === category);
        setProducts(products);
        if (products.length) {
          setSelectedProduct(products[0]);
        }
      } catch (e) {
        setFetchError('Could not get products. Please reload the page. ' + e.message);
      }
    };
    doAsync();
  }, [category]);

  const handleAmountChange = useCallback(inputVal => {
    setAmount(parseInt(inputVal));
  }, []);

  const handleIntervalChange = useInputValueCallback((inputVal, inputName) => {
    const index = parseInt(inputName.split(SEP)[1]);
    setIntervals(prevIntervals => {
      const newIntervals = [...prevIntervals];
      newIntervals[index] = inputVal;
      return newIntervals;
    });
  });

  const handleAddInterval = useCallback(() => {
    setIntervals(prevState => {
      const newIntervals = [...prevState];
      newIntervals.push('');
      return newIntervals;
    });
  }, []);

  const handleContactChange = useInputValueCallback(inputVal => {
    setContact(inputVal);
  });

  const toggleLocationModal = useCallback(() => {
    setShowLocationModal(prevState => !prevState);
  }, []);

  const handleLocationChange = useCallback(
    result => {
      setCoordinate(result.coordinate);
      setAddress({street: result.street, area: result.area, postcode: result.postcode});
      toggleLocationModal();
    },
    [toggleLocationModal]
  );

  const handleNotesChange = useInputValueCallback(inputVal => {
    setNotes(inputVal);
  });

  const handleAddOrder = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setValidationErrors({});

      if (!selectedProduct) {
        return;
      }
      const contents: OrderItem = {
        item: selectedProduct,
        amount,
      };

      if (isOffer) {
        const offer: Offer = {
          user_id: user.id,
          pickupStreet: address.street || '',
          pickupArea: address.area || '',
          pickupPostcode: address.postcode || '',
          pickupName: contact,
          pickupNotes: notes,
          pickupInterval: intervals.join('\n'),
          pickupCoordinate: coordinate || null,
        };

        try {
          const taskId = await createOffer(offer, contents);
          history.push(URLS.task_info(taskId), {
            message: 'Your offer was created',
            referrer: URLS_OFFERS.offers,
          });
        } catch (e) {
          console.error(e);
          setValidationErrors(mapValidationErrors(e?.body?.errors || []));
          setSubmitError('Your offer could not be sent. ' + e.message);
          window.scrollTo(0, 0);
        }
      } else {
        const request: Request = {
          user_id: user.id,
          deliveryStreet: address.street || '',
          deliveryArea: address.area || '',
          deliveryPostcode: address.postcode || '',
          deliveryName: contact,
          deliveryNotes: notes,
          deliveryInterval: intervals.join('\n'),
          deliveryCoordinate: coordinate || null,
        };

        try {
          const taskId = await createRequest(request, contents);
          history.push(URLS.task_info(taskId), {
            message: 'Your request was created',
            referrer: URLS_REQUESTS.requests,
          });
        } catch (e) {
          console.error(e);
          setValidationErrors(mapValidationErrors(e?.body?.errors || []));
          setSubmitError('Your request could not be sent. ' + e.message);
          window.scrollTo(0, 0);
        }
      }
    },
    [isOffer, selectedProduct, user.id, address, coordinate, contact, notes, intervals, amount, history]
  );

  const categoryName = category ? NAMES[category] : '';
  const title = `New ${categoryName} ${isOffer ? 'offer' : 'request'}`;
  const backUrl = isOffer ? URLS_OFFERS.offer_add : URLS_REQUESTS.request_add;
  const productTitle = isOffer ? `What ${categoryName} can you offer?` : `What ${categoryName} do you need?`;
  const unitName = selectedProduct?.unit;
  const transferTitle = isOffer ? 'Available collection' : 'Available delivery';
  const transferDescription = isOffer
    ? 'When can other people pick these units up from you?'
    : 'When can other people deliver these units to you?';
  const contactTitle = isOffer ? 'Person to collect from' : 'Person to deliver to';
  const contactDescription = isOffer ? 'Who can people pick this up from?' : 'Who can people deliver this to?';
  const addressTitle = isOffer ? 'Collection address' : 'Delivery address';
  const notesTitle = 'Additional notes';
  const notesDescription = isOffer
    ? 'These will be seen by whoever responds to your offer.'
    : 'These will be seen by whoever responds to your request.';
  const validationPrefix = isOffer ? 'Collection' : 'Delivery';

  return (
    <AppContainer>
      <PageHeader text={title} iconName="previous" to={backUrl} />
      <ErrorFree error={submitError} />
      <ErrorFree error={fetchError}>
        <ConditionalSpinner spinner={!products}>
          <Form className={'d-flex flex-column limit-width'} onSubmit={handleAddOrder}>
            <FormGroup className="p-3">
              <Label className={'text-capitalize'}>{categoryName}</Label>
              <FormText className="mb-3">{productTitle}</FormText>
              <ValidationError prefix={validationPrefix} keys={['productId']} errors={validationErrors} />
              <ProductSelector products={products} selectedProduct={selectedProduct} onChange={setSelectedProduct} />
            </FormGroup>
            <FormGroup className="p-3">
              <Label for="amount">Units{unitName ? ` (${unitName})` : ''}</Label>
              <FormText className="mb-3">How many units {isOffer ? 'can you offer' : 'do you need'}?</FormText>
              <ValidationError prefix={validationPrefix} keys={['amount']} errors={validationErrors} />
              <SideButtonsInput
                id="amount"
                type={'number'}
                name={'amount'}
                value={isNaN(amount) ? '' : amount}
                onChange={handleAmountChange}
              />
            </FormGroup>
            <FormGroup className="p-3">
              <Label>{transferTitle}</Label>
              <FormText className="mb-3">{transferDescription}</FormText>
              <ValidationError
                prefix={validationPrefix}
                keys={['pickupInterval', 'deliveryInterval']}
                errors={validationErrors}
              />
              {intervals.map((e, index) => (
                <div key={index} className={'d-flex align-items-center'}>
                  <Input
                    type={'text'}
                    className={'my-2'}
                    name={`interval${SEP}${index}`}
                    value={intervals[index]}
                    onChange={handleIntervalChange}
                    bsSize="lg"
                  />
                  <span style={{width: 77}}>
                    {index === intervals.length - 1 ? (
                      <Button
                        className={'rounded-circle p-0 btn-small ml-3'}
                        color={'dark'}
                        onClick={handleAddInterval}
                      >
                        <img src={plus} alt={'Add time slot'} />
                      </Button>
                    ) : (
                      NBSP
                    )}
                  </span>
                </div>
              ))}
            </FormGroup>
            <FormGroup className="p-3">
              <Label for="contact">{contactTitle}</Label>
              <FormText className="mb-3">{contactDescription}</FormText>
              <ValidationError
                prefix={validationPrefix}
                keys={['pickupName', 'deliveryName']}
                errors={validationErrors}
              />
              <Input
                id="contact"
                type={'text'}
                name={'contact'}
                value={contact}
                onChange={handleContactChange}
                bsSize="lg"
              />
            </FormGroup>
            <FormGroup className="p-3">
              <Label for="address">{addressTitle}</Label>
              {coordinate && <TaskMap openButton={false} fromCoordinate={coordinate} style={{height: 150}} />}
              <ValidationError
                prefix={validationPrefix}
                keys={[
                  'pickupStreet',
                  'pickupArea',
                  'pickupPostcode',
                  'deliveryStreet',
                  'deliveryArea',
                  'deliveryPostcode',
                  'pickupLatitude',
                  'deliveryLatitude',
                  'pickupLongitude',
                  'deliveryLongitude',
                ]}
                errors={validationErrors}
              />
              <p className={'d-flex justify-content-between align-items-center mt-3'}>
                <FormText className={address ? '' : 'font-italic'}>
                  {formatAddress(address) || 'Please choose an address'}
                </FormText>
                <Button type={'button'} color={'dark'} onClick={toggleLocationModal} size="lg">
                  Edit
                </Button>
              </p>
              <Modal isOpen={showLocationModal} toggle={toggleLocationModal}>
                <EditLocationModal
                  coordinate={coordinate}
                  street={address.street}
                  area={address.area}
                  postcode={address.postcode}
                  onCancel={toggleLocationModal}
                  onResult={handleLocationChange}
                />
              </Modal>
            </FormGroup>
            <FormGroup className="p-3">
              <Label for="notes">{notesTitle}</Label>
              <FormText className="mb-3">{notesDescription}</FormText>
              <ValidationError
                prefix={validationPrefix}
                keys={['pickupNotes', 'deliveryNotes']}
                errors={validationErrors}
              />
              <Input
                id="notes"
                type={'textarea'}
                rows={3}
                name={'notes'}
                value={notes}
                onChange={handleNotesChange}
                bsSize="lg"
              />
            </FormGroup>
            <FormGroup className="p-3 border-0">
              <Button block type={'submit'} color={'dark button-block float-right'} size="lg">
                Add {isOffer ? 'Offer' : 'Request'}
              </Button>
            </FormGroup>
          </Form>
        </ConditionalSpinner>
      </ErrorFree>
    </AppContainer>
  );
};

export default withUser(CreateOrderPage);
