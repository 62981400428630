import React, {ChangeEvent, FormEvent, useCallback, useEffect, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {Assurance, getRoleName, User, UserRole} from '../types';
import withUser from '../common/withUser';
import URLS from './urls';
import {listAssurances, updateUser} from '../api/api.users';
import {useHistory} from 'react-router-dom';
import ErrorFree from '../common/ErrorFree';
import {useDispatch} from 'react-redux';
import {setLoggedUser} from '../redux/user';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import ConditionalSpinner from '../common/ConditionalSpinner';
import roles from '../signup/roles';
import {IconCircleDropShadow} from '../common/Icon';

type PropsType = {
  user: User;
};

const EditRolesPage = (props: PropsType) => {
  const {user} = props;
  const [assuranceList, setAssuranceList] = useState<{[role: string]: Assurance[]}>();
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [data, setData] = useState<UserRole[]>(user.roles);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleAssuranceChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const roleName = getRoleName(event.target.name);
    const assuranceId = event.target.value;
    if (!roleName) {
      return;
    }
    setData(prevRoles => {
      const newRoles = [...prevRoles].filter(r => r.name !== roleName);
      newRoles.push({name: roleName, assuranceId: assuranceId ? assuranceId : null});
      return newRoles;
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setLoading(true);
      setValidationError(null);
      try {
        const response = await updateUser({
          ...user,
          roles: data,
        });
        dispatch(setLoggedUser(response.user));
        history.push(URLS.profile, {message: 'Your role info was updated'});
      } catch (e) {
        setValidationError(e.message);
      } finally {
        setLoading(false);
        window.scrollTo(0, 0);
      }
    },
    [dispatch, history, user, data]
  );

  useEffect(() => {
    const doAsync = async () => {
      try {
        setAssuranceList(await listAssurances());
      } catch (e) {
        setFetchError('There was an error. Please reload the page. ' + e.message);
      }
    };
    doAsync();
  }, []);

  return (
    <AppContainer>
      <PageHeader iconName={'previous'} to={URLS.profile} text={'Edit Profile'} />
      <ErrorFree error={fetchError}>
        <ConditionalSpinner spinner={!assuranceList}>
          <ErrorFree error={validationError} />
          <Form className="p-4 limit-width" onSubmit={handleSubmit}>
            {['supplier', 'maker', 'healthcare', 'transporter'].map(roleName => {
              const role = data.find(r => r.name === roleName);
              if (!role) {
                return null;
              }
              return (
                <FormGroup key={role.name} className="py-2 border-0">
                  <div className={'d-flex'}>
                    <IconCircleDropShadow name={roles[role.name].iconName} />
                    <div className={'d-flex flex-column flex-grow-1 ml-4'}>
                      <Label>{roles[role.name].name}</Label>
                      <Input
                        type={'select'}
                        name={role.name}
                        value={role.assuranceId || undefined}
                        onChange={handleAssuranceChange}
                      >
                        <option value={''}>None</option>
                        {!!assuranceList &&
                          assuranceList[role.name].map(a => (
                            <option key={a.id} value={a.id}>
                              {a.name}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </div>
                </FormGroup>
              );
            })}
            <ConditionalSpinner spinner={loading}>
              <Button type="submit" size="lg" color="dark" className="mt-4" block>
                Update role info
              </Button>
            </ConditionalSpinner>
          </Form>
        </ConditionalSpinner>
      </ErrorFree>
    </AppContainer>
  );
};

export default withUser(EditRolesPage);
