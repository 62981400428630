import {IconName} from '../icons';
import {RoleName} from '../types';

export type Role = {
  id: 'supplier' | 'maker' | 'transporter' | 'healthcare';
  name: string;
  iconName: IconName;
  description: string;
  assuranceText: string;
};

const roles: {[key in RoleName]: Role} = {
  supplier: {
    id: 'supplier',
    name: 'Material provider',
    iconName: 'materialProvider',
    description: 'I can supply materials to those with 3D Printers',
    assuranceText: 'Select your accreditation',
  },
  maker: {
    id: 'maker',
    name: 'Maker',
    iconName: 'maker',
    description: 'I can create PPE',
    assuranceText: 'Select your accreditation',
  },
  healthcare: {
    id: 'healthcare',
    name: 'Health professional',
    iconName: 'healthWorker',
    description: 'I’m a health professional who needs PPE',
    assuranceText: 'What kind of institution is yours?',
  },
  transporter: {
    id: 'transporter',
    name: 'Delivery',
    iconName: 'transport',
    description: 'I can deliver PPE and Materials',
    assuranceText: 'Select your experience',
  },
};

export default roles;
