import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useOurSelector} from '../redux/rootReducer';
import {getLoggedUser} from '../redux/user';
import URLS from '../urls';
import AppContainer from './AppContainer';
import {Alert} from 'reactstrap';

/**
 * A Route that can only be accessed if user is an admin. Otherwise an error page is shown.
 */

const AdminRoute = (props: RouteProps) => {
  const user = useOurSelector(getLoggedUser);
  if (!user) {
    return <Redirect to={URLS.login} />;
  }
  if (user.isAdmin) {
    return <Route {...props} />;
  }

  return (
    <Route {...props}>
      <AppContainer>
        <Alert color={'danger'}>You don't have enough permission</Alert>
      </AppContainer>
    </Route>
  );
};

export default AdminRoute;
