import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const CrossIcon: FC<Props> = props => {
  const {className, size = 54, color = 'white'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
      <polygon
        fill={color}
        fillRule="nonzero"
        points="36.2052232 1 40 4.79477679 24.2947294 20.5005257 40 36.2052232 36.2052232 40 20.5005257 24.2947294 4.79477679 40 1 36.2052232 16.7063219 20.5005257 1 4.79477679 4.79477679 1 20.5005257 16.7063219"
      />
    </svg>
  );
};
export default CrossIcon;
