import React, {FC, useCallback} from 'react';
import AppContainer from '../common/AppContainer';
import {Container, Button} from 'reactstrap';
import Icon from '../common/Icon';
import {useHistory} from 'react-router-dom';

const defaults = {
  title: 'Not yet implemented',
};

type Props = {
  title?: string;
  subtitle?: string;
};

const WipPage: FC<Props> = props => {
  const {title = defaults.title} = props;

  const history = useHistory();

  const handleClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <AppContainer>
      <Container className="d-flex  h-100 align-items-center justify-content-center">
        <Container className="d-flex flex-column align-items-center p-4 justify-content-center limit-width">
          <Icon className="mb-5" name="healthWorker" size={128} />
          <h1 className="pb-5">{title}</h1>
          <Button className="mt-5" size="lg" color="secondary" onClick={handleClick} block>
            Continue
          </Button>
        </Container>
      </Container>
    </AppContainer>
  );
};

export default WipPage;
