import React, {useCallback} from 'react';
import {FC} from 'react';
import {FormGroup, Label} from 'reactstrap';
import {IconName} from '../../icons';
import {IconCircleDropShadow} from '../../common/Icon';
import CheckBox from '../../common/CheckBox';

type Props = {
  id: string;
  iconName: IconName;
  label: string;
  checked?: boolean;
  onChange: (id: string, checked: boolean) => void;
};

const RoleSelector: FC<Props> = props => {
  const {id, iconName, label, checked, onChange} = props;

  const handleClick = useCallback(() => {
    onChange(id, !checked);
  }, [onChange, id, checked]);

  return (
    <button
      type={'button'}
      className="border-0 px-0 py-2 my-1 d-flex align-items-stretch bg-white"
      onClick={handleClick}
    >
      <FormGroup className={'d-flex align-items-center flex-grow-1 p-0'} check>
        <IconCircleDropShadow name={iconName} />
        <Label className="mx-4 flex-grow-1 text-left" check>
          {label}
        </Label>
        <CheckBox id={id} checked={checked} />
      </FormGroup>
    </button>
  );
};

export default RoleSelector;
