import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container, Button} from 'reactstrap';

const text = `PPE Hive was developed for a beta release pro-bono by AUAR and Calvium.
You can donate securely via GoFundMe will support further development of the application to scale up to a UK-wide release in collaboration with Autonomy, a progressive think tank focusing on work (author of 'The Protection We Need: Decentralised and Local Sourcing of Medical Supplies' April 2020) this summer.`;

const campaignUrl = 'https://gf.me/u/x6z9bh';

const HowCanIHelpPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-4 limit-width">
        <h1 className="py-3">{'How can I help?'}</h1>
        {text.split('\n').map(a => (
          <p>{a}</p>
        ))}
        <Button
          className="mt-5"
          size="lg"
          color="dark"
          href={campaignUrl}
          block
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Donate Now
        </Button>
      </Container>
    </AppContainer>
  );
};

export default HowCanIHelpPage;
