import React, {FC} from 'react';
import {Task} from '../types';

type Props = {
  className?: string;
  task: Task;
};

const TaskImage: FC<Props> = props => {
  const {className, task} = props;
  return (
    <div className={className}>
      <img
        src={task.contents.item.image?.src}
        width={36}
        height={36}
        style={{objectFit: 'contain'}}
        alt={task.contents.item.name}
      />
    </div>
  );
};

export default TaskImage;
