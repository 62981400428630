const URLS = {
  requests: '/requests',
  requests_open: '/requests/open',
  requests_mine: '/requests/mine',
  requests_claimed: '/requests/claimed',
  request_add: '/requests/add',
  request_add_category: (category = ':category') => `/requests/add/${category}`,
  request_claim: (taskId = ':taskId') => `/requests/${taskId}/claim`,
};

export default URLS;
