export const plus = require('./plus.png');
export const minus = require('./minus.png');
export const offer = require('./offer.png');
export const transport = require('./transport.png');
export const request = require('./request.png');
export const pin = require('./pin.png');
export const fdm = require('./fdm.png');
export const acetate = require('./acetate.png');
export const rubberband = require('./rubberband.png');
export const shield = require('./shield.png');
export const mask = require('./mask.png');
export const handle = require('./handle.png');
export const info = require('./info.png');
export const login = require('./login.png');
export const checkboxOn = require('./checkbox_on.png');
export const checkboxOff = require('./checkbox_off.png');
export const markerWhite = require('./markerWhite.png');
export const markerBlack = require('./markerBlack.png');
export const howThisWorks1 = require('./how-this-works-1.png');
export const howThisWorks2 = require('./how-this-works-2.png');
export const howThisWorks3 = require('./how-this-works-3.png');
export const howThisWorks4 = require('./how-this-works-4.png');
export const aboutProject = require('./about-project.png');
export const bptransporting = require('./bptransporting.png');
export const bpcollecting = require('./bpcollecting.png');
export const bpdelivering = require('./bpdelivering.png');
export const bpoffering = require('./bpoffering.png');
export const bprequesting = require('./bprequesting.png');
export const verify = require('./verify.png');
export const credits = require('./credits.png');
