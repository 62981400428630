import React from 'react';
import OffersPage from './OffersPage';
import OpenOffersPage from './OpenOffersPage';
import MyOffersPage from './MyOffersPage';
import ClaimedOffersPage from './ClaimedOffersPage';
import AddOfferPage from './AddOfferPage';
import CreateOrderPage from '../pages/CreateOrderPage';
import ClaimOrderPage from '../pages/ClaimOrderPage';
import * as route from '../common/util/routeShorthand';
import URLS from './urls';

export const ROUTES = [
  route.loggedIn(URLS.offers, <OffersPage />),
  route.loggedIn(URLS.offers_open, <OpenOffersPage />),
  route.loggedIn(URLS.offers_mine, <MyOffersPage />),
  route.loggedIn(URLS.offers_claimed, <ClaimedOffersPage />),
  route.loggedIn(URLS.offer_add, <AddOfferPage />),
  route.loggedIn(URLS.offer_add_category(), <CreateOrderPage action={'offer'} />),
  route.loggedIn(URLS.offer_claim(), <ClaimOrderPage action="offer" />),
];
