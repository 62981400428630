import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const RequestIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke={color}>
        <rect height="30" rx="3" width="23" x="9" y="5" />
        <g strokeLinecap="round">
          <path d="m13.322742 10.583122h14.099273" />
          <path d="m13.322742 15.313532h14.099273" />
          <path d="m13.322742 20.043942h14.099273" />
          <path d="m13.322742 24.774351h6.21733" />
          <path
            d="m29.06066 18.43934h3v12h-3z"
            fill="#fff"
            strokeLinejoin="round"
            transform="matrix(.70710678 .70710678 -.70710678 .70710678 26.232233 -14.451533)"
          />
          <path
            d="m23.7573593 28.2426407 1.5 3 1.5-3"
            strokeLinejoin="round"
            transform="matrix(.70710678 .70710678 -.70710678 .70710678 28.428932 -9.148232)"
          />
        </g>
      </g>
    </svg>
  );
};
export default RequestIcon;
