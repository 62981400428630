import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';

const PrivacyPolicyPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-4 limit-width">
        <h1 className="py-3">Privacy Policy</h1>
        <a
          className="button-block btn-dark btn-block btn-lg text-center"
          href={'/privacy.pdf'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          View PDF
        </a>
      </Container>
    </AppContainer>
  );
};

export default PrivacyPolicyPage;
