import React, {useCallback, FC} from 'react';
import {ListGroupItem} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import Badge from './Badge';
import './ListButton.css';
import Icon from './Icon';

type Props = {
  text: string;
  variant?: 'next' | 'add';
  badgeCount?: number;
  to?: string;
  url?: string;
  disabled?: boolean;
  icon?: string;
};

const ListButton: FC<Props> = props => {
  const {text, variant = 'next', badgeCount = 0, to, url, disabled = false, icon} = props;
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (to) {
      history.push(to);
    }
  }, [history, to]);

  return (
    <ListGroupItem
      className="ListButton d-flex justify-content-between text-left align-items-center py-4"
      onClick={handleClick}
      tag={url ? 'a' : 'button'}
      disabled={disabled}
      href={url}
    >
      {icon && <img src={icon} alt={text} className={'mr-4'} />}
      <span className="flex-grow-1">{text}</span>
      {badgeCount > 0 && <Badge className={'mr-3'} text={`${badgeCount}`} />}
      <span className="ml-3">
        <Icon name={variant} size={54} />
      </span>
    </ListGroupItem>
  );
};

export default ListButton;
