import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {useOurSelector} from '../redux/rootReducer';
import {getLoggedUser} from '../redux/user';
import URLS from '../urls';

/**
 * A Route that can only be accessed if user is logged out. Otherwise they are redirected to Dashboard.
 */
const LoggedOutRoute = (props: RouteProps) => {
  const user = useOurSelector(getLoggedUser);
  if (user) {
    return <Redirect to={URLS.dashboard} />;
  }
  return <Route {...props} />;
};

export default LoggedOutRoute;
