import React from 'react';
import * as route from '../common/util/routeShorthand';
import SignupPage from './SignupPage';

export const URLS = {
  signup: '/sign-up',
  // signupRoles: '/sign-up/roles',
  // signupContact: '/sign-up/contact',
  // signupLocation: '/sign-up/location',
  // signup_role: (role = ':role') => `/signup/${role}`,
};

export const ROUTES = [
  route.loggedOut(URLS.signup, <SignupPage />),
  // route.loggedOut(URLS.signupRoles, <SignupRolesPage />),
  // route.loggedOut(URLS.signupContact, <SignupContantPage />),
  // route.loggedOut(URLS.signupLocation, <SignupLocationPage />),
];
