import React, {ReactNode} from 'react';
import {Spinner} from 'reactstrap';

type PropsType = {
  spinner: boolean;
  children?: ReactNode;
};

/**
 * Display a spinner if the given boolean is true; or the given children otherwise.
 */
const ConditionalSpinner = (props: PropsType) => {
  const {spinner, children} = props;
  return spinner ? (
    <div className={'d-flex justify-content-center m-5'}>
      <Spinner />
    </div>
  ) : (
    <>{children}</>
  );
};

export default ConditionalSpinner;
