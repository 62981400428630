import React, {useCallback} from 'react';
import {Col, Row, Badge} from 'reactstrap';
import {useHistory, useLocation} from 'react-router-dom';
import {Task, UID} from '../types';
import {isUserOffer, isUserRequest, isValid, unitsAmount} from './util/taskUtil';
import './TaskSummary.css';
import TaskStatusIndicator from './TaskStatusIndicator';
import TaskId from './TaskId';
import TaskImage from './TaskImage';
import TaskActionLabel from './TaskActionLabel';

type PropsType = {
  /* The task whose info is displayed. */
  task: Task;

  /* ID of the user that is viewing the task. Presented information and available actions depend on this. */
  userId: UID;

  /* URL the "More Info" button points to. If not given, the button is not shown. */
  to?: string;
};

/**
 * Summarises information about the given task.
 */
const TaskSummary = (props: PropsType) => {
  const {task, userId, to} = props;
  const history = useHistory();
  const location = useLocation();
  const handleClick = useCallback(() => to && history.push(to, {referrer: location}), [history, to, location]);

  if (!isValid(task)) {
    console.warn(`Skipping task ${task.id}. Invalid state`, task);
    return null;
  }

  const pickupLocation = [task.offer?.pickupPostcode, task.offer?.pickupArea].filter(Boolean).join(', ');
  const deliveryLocation = [task.request?.deliveryPostcode, task.request?.deliveryArea].filter(Boolean).join(', ');
  const relation = isUserOffer(task, userId) ? 'Offered by you' : isUserRequest(task, userId) ? 'Requested by you' : '';

  return (
    <button onClick={handleClick} className="m-0 py-2 px-0 btn-block TaskSummaryContainerButton">
      <Row noGutters className={'TaskSummary p-3'}>
        <TaskImage task={task} className="pr-3" />
        <Col className="description">
          <Row>
            <Col>
              <h4>{unitsAmount(task.contents)}</h4>
            </Col>
            <Col xs="auto">
              <TaskStatusIndicator task={task} />
            </Col>
          </Row>
          <Row>
            <Col>
              {task.offer && <p>From: {pickupLocation}</p>}
              {task.request && <p>To: {deliveryLocation}</p>}
              {relation.length > 0 && (
                <p className={'lead'}>
                  <Badge color="secondary">{relation}</Badge>
                </p>
              )}
              <TaskId task={task} />
            </Col>
            <Col xs="auto">
              <TaskActionLabel task={task} userId={userId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </button>
  );
};

export default TaskSummary;
