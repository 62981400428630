import React, {FormEvent, useCallback, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {User} from '../types';
import withUser from '../common/withUser';
import URLS from './urls';
import {updateUser} from '../api/api.users';
import {useHistory} from 'react-router-dom';
import ErrorFree from '../common/ErrorFree';
import {useDispatch} from 'react-redux';
import {setLoggedUser} from '../redux/user';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import ConditionalSpinner from '../common/ConditionalSpinner';
import {useInputValueCallback} from '../common/hooks/useInputValueCallback';
import ValidationError, {mapValidationErrors} from '../common/ValidationError';

type ContactData = {
  name?: string;
  organisation?: string;
  phone?: string;
  aboutme?: string;
  privacyAgreed?: boolean;
  termsAgreed?: boolean;
};

type PropsType = {
  user: User;
};

const EditLocationPage = (props: PropsType) => {
  const {user} = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});
  const [data, setData] = useState<ContactData>({
    name: user.name,
    organisation: user.organisation,
    phone: user.phone,
    aboutme: user.aboutme,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = useInputValueCallback((inputVal, inputName) => {
    setData(prevData => ({...prevData, [inputName]: inputVal}));
  });

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setLoading(true);
      setError(null);
      setValidationErrors({});
      try {
        const response = await updateUser({
          ...user,
          ...data,
        });
        dispatch(setLoggedUser(response.user));
        history.push(URLS.profile, {message: 'Your contact info was updated'});
      } catch (e) {
        setError(e.message);
        setValidationErrors(mapValidationErrors(e?.body?.errors || []));
      } finally {
        setLoading(false);
        window.scrollTo(0, 0);
      }
    },
    [dispatch, history, user, data]
  );

  return (
    <AppContainer>
      <PageHeader iconName={'previous'} to={URLS.profile} text={'Edit Profile'} />
      <ErrorFree error={error} />
      <Form className="p-4 limit-width" onSubmit={handleSubmit}>
        <FormGroup className="py-2 border-0">
          <Label for="name">Name</Label>
          <Input id="name" type="text" name="name" value={data.name || ''} onChange={handleChange} bsSize="lg" />
          <ValidationError keys={['name']} errors={validationErrors} />
        </FormGroup>
        <FormGroup className="py-2 border-0">
          <Label for="organisation">Organisation</Label>
          <Input
            id="organisation"
            type="text"
            name="organisation"
            value={data.organisation}
            onChange={handleChange}
            bsSize="lg"
          />
          <ValidationError keys={['organisation']} errors={validationErrors} />
        </FormGroup>
        <FormGroup className="py-2 border-0">
          <Label for="phone">Phone number</Label>
          <Input id="phone" type="tel" name="phone" value={data.phone || ''} onChange={handleChange} bsSize="lg" />
          <ValidationError keys={['phone']} errors={validationErrors} />
        </FormGroup>
        <FormGroup className="py-2 border-0">
          <Label for="aboutme">About me</Label>
          <Input
            id="aboutme"
            type="textarea"
            rows={4}
            name="aboutme"
            value={data.aboutme || ''}
            onChange={handleChange}
            bsSize="lg"
          />
        </FormGroup>
        <ConditionalSpinner spinner={loading}>
          <Button type="submit" size="lg" color="dark" className="mt-4" block>
            Update contact info
          </Button>
        </ConditionalSpinner>
      </Form>
    </AppContainer>
  );
};

export default withUser(EditLocationPage);
