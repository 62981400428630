import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const OfferSubmittedIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <g fill={color} fillRule="nonzero">
        <path d="M24.5637904,1.1 C27.4733882,1.1 29.8455198,3.40116583 29.9595026,6.28281481 L29.9637904,6.5 L29.9636155,17.5979269 C35.1867577,18.4450054 39.1745763,22.9759836 39.1745763,28.4383667 C39.1745763,34.5038829 34.2575039,39.4209553 28.1919877,39.4209553 C25.2248641,39.4209553 22.5325587,38.2443188 20.5562222,36.3321963 L6.5,36.3317411 C3.59040229,36.3317411 1.21827067,34.0305753 1.1042878,31.1489263 L1.1,30.9317411 L1.1,6.5 C1.1,3.59040229 3.40116583,1.21827067 6.28281481,1.1042878 L6.5,1.1 L24.5637904,1.1 Z M28.1919877,19.2557781 C23.120584,19.2557781 19.0093991,23.3669631 19.0093991,28.4383667 C19.0093991,33.5097704 23.120584,37.6209553 28.1919877,37.6209553 C33.2633913,37.6209553 37.3745763,33.5097704 37.3745763,28.4383667 C37.3745763,23.3669631 33.2633913,19.2557781 28.1919877,19.2557781 Z M24.7549827,2.90499009 L24.5637904,2.9 L6.5,2.9 C4.57591119,2.9 3.00437721,4.40946661 2.90499009,6.30880777 L2.9,6.5 L2.9,30.9317411 C2.9,32.8558299 4.40946661,34.4273639 6.30880777,34.5267511 L6.5,34.5317411 L19.0532602,34.5317411 C18.2593359,33.343123 17.6910544,31.9911737 17.4096708,30.5369209 L7.00338983,30.5372111 L7.00338983,29.6372111 L17.2740609,29.637046 C17.2313271,29.243356 17.2093991,28.8434222 17.2093991,28.4383667 C17.2093991,27.5147442 17.3234136,26.6177512 17.5381361,25.7606943 L7.00338983,25.7612481 L7.00338983,24.8612481 L17.8054326,24.8603193 C18.3061988,23.4063822 19.1036516,22.0905686 20.125459,20.9852106 L7.00338983,20.9852851 L7.00338983,20.0852851 L21.0634731,20.0833601 C22.97504,18.4508452 25.453641,17.4628531 28.162844,17.455816 L28.1637904,6.5 C28.1637904,4.57591119 26.6543238,3.00437721 24.7549827,2.90499009 Z" />
        <polygon points="23.9662862 6.81872111 23.9662862 7.71872111 7.00338983 7.71872111 7.00338983 6.81872111" />
        <polygon points="23.9662862 11.0640216 23.9662862 11.9640216 7.00338983 11.9640216 7.00338983 11.0640216" />
        <polygon points="23.9662862 15.8399846 23.9662862 16.7399846 7.00338983 16.7399846 7.00338983 15.8399846" />
        <polygon points="33.2964738 24.0534681 34.4977241 25.3939895 25.7940053 33.1934515 21.6638321 28.2465285 23.045567 27.0929216 25.98 30.608" />
      </g>
    </svg>
  );
};
export default OfferSubmittedIcon;
