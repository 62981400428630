import React, {FC, useCallback, useState} from 'react';
import {Container, Row, Col} from 'reactstrap';
import AppContainer from '../common/AppContainer';
import {useHistory} from 'react-router-dom';
import BrandHeader from '../common/BrandHeader';
import SignupRolesForm from './SignupRolesForm';
import SignupContactForm from './SignupContactForm';
import SignupLocationForm from './SignupLocationForm';
import {useMemo} from 'react';
import URLS from '../urls';
import {createUser, UserRequest} from '../api/api.users';
import ErrorFree from '../common/ErrorFree';
import ConditionalSpinner from '../common/ConditionalSpinner';
import {mapValidationErrors} from '../common/ValidationError';

type WizardStep = {
  index: number;
  id: string;
  title: string;
  description?: string;
  Component: FC<{
    index: number;
    initialData: UserRequest;
    onSubmit: (index: number, data: UserRequest) => void;
    validationErrors: {[key: string]: string};
  }>;
};

const wizardSteps: WizardStep[] = [
  {
    index: 0,
    id: 'roles',
    title: 'Who are you?',
    Component: SignupRolesForm,
  },
  {
    index: 1,
    id: 'contact',
    title: 'Contact details',
    description: 'We will contact you to confirm your account.',
    Component: SignupContactForm,
  },
  {
    index: 2,
    id: 'location',
    title: 'Your location',
    description:
      'This will help people who need to find you get to the right place. Drag the map to find the best place to show people on the map.',
    Component: SignupLocationForm,
  },
];

const SignupPage: FC = () => {
  const history = useHistory();
  const [userRequest, setUserRequest] = useState<UserRequest>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});
  const [currentWizardStep, setCurrentWizardStep] = useState<WizardStep>(wizardSteps[0]);

  const FormComponent = useMemo(() => currentWizardStep.Component, [currentWizardStep]);

  const handleWizardStepSubmit = useCallback(
    async (index: number, data: UserRequest) => {
      const newRequest = {...userRequest, ...data};
      setUserRequest(newRequest);
      if (index < wizardSteps.length - 1) {
        setCurrentWizardStep(wizardSteps[index + 1]);
      } else {
        try {
          setError(null);
          setValidationErrors({});
          setLoading(true);
          await createUser(newRequest);
          setLoading(false);
          history.push(URLS.login, {message: 'Your account was created. Check your email for a verification link.'});
        } catch (e) {
          const map = mapValidationErrors(e?.body?.errors || []);
          setValidationErrors(map);
          setCurrentWizardStep(wizardSteps[firstStepWithErrors(map, e?.body?.httpStatusCode)]);
          setError(e.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [userRequest, setCurrentWizardStep, history]
  );

  return (
    <AppContainer>
      <BrandHeader iconName="healthWorker" />
      <ErrorFree error={error} />
      <Container className="p-0 limit-width">
        <ConditionalSpinner spinner={loading}>
          <Row className="p-4 border-bottom" noGutters>
            <Col>
              <h1 className="pb-3 mb-3">Sign Up</h1>
              <h3>{currentWizardStep.title}</h3>
              <p>{currentWizardStep.description}</p>
            </Col>
            <p className="p-3">{`${currentWizardStep.index + 1} / ${wizardSteps.length}`}</p>
          </Row>
          <FormComponent
            index={currentWizardStep.index}
            initialData={userRequest}
            onSubmit={handleWizardStepSubmit}
            validationErrors={validationErrors}
          />
        </ConditionalSpinner>
      </Container>
    </AppContainer>
  );
};

export default SignupPage;

function firstStepWithErrors(errors: {[key: string]: string}, httpStatusCode: number) {
  const params = Object.keys(errors);

  if (params.includes('roles')) {
    return 0;
  }
  if (
    ['name', 'organisation', 'phone', 'aboutme', 'email', 'password', 'privacyAgreed', 'termsAgreed'].some(p =>
      params.includes(p)
    )
  ) {
    return 1;
  }
  if (httpStatusCode === 409) {
    return 1;
  }
  if (['latitude', 'longitude', 'street', 'area', 'postcode'].some(p => params.includes(p))) {
    return 2;
  }
  return 0;
}
