import React from 'react';
import {useOurSelector} from '../redux/rootReducer';
import {getLoggedUser} from '../redux/user';
import {User} from '../types';

type HasUser = {
  user: User;
};

type OmitUser<T> = Omit<T, 'user'>;

function withUser<T extends HasUser>(Component: React.ComponentType<T>): React.FunctionComponent<OmitUser<T>> {
  return (props: OmitUser<T>) => {
    const user = useOurSelector(getLoggedUser);
    if (!user) {
      console.warn('Calling HOC withUser without a user saved in redux store');
      return null;
    }
    return <Component {...(props as T)} user={user} />;
  };
}

export default withUser;
