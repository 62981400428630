import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {ListGroup, Container} from 'reactstrap';
import ListButton from '../common/ListButton';

const BestPractice: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="py-5 px-4 border-bottom limit-width">
        <h1>Best Practice</h1>
      </Container>
      <ListGroup className="border-bottom limit-width" flush>
        <ListButton text="...for offering" to={URLS.bestPracticeOffering} />
        <ListButton text="...for requesting" to={URLS.bestPracticeRequesting} />
        <ListButton text="...for transporting" to={URLS.bestPracticeTransporting} />
        <ListButton text="...for collecting" to={URLS.bestPracticeCollecting} />
        <ListButton text="...for delivering" to={URLS.bestPracticeDelivering} />
      </ListGroup>
    </AppContainer>
  );
};

export default BestPractice;
