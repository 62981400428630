import React from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from '../urls';
import URLS_TRANSPORT from './urls';
import {User} from '../types';
import {findOpenTransport} from '../api/api.tasks';
import TaskList from '../common/TaskList';
import withUser from '../common/withUser';

type PropsType = {
  user: User;
};

const OpenTransportPage = (props: PropsType) => {
  const {user} = props;

  return (
    <AppContainer>
      <PageHeader text="Transport needed" iconName="previous" to={URLS_TRANSPORT.transport} bottomMargin={false} />
      <TaskList
        className={'limit-width'}
        taskProvider={findOpenTransport}
        userId={user.id}
        moreInfo={URLS.task_info}
        noResults={'There are no open transport tasks at the moment.'}
      />
    </AppContainer>
  );
};

export default withUser(OpenTransportPage);
