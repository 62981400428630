import React, {FormEvent, useCallback, FC} from 'react';
import {Form, Button, FormGroup, Label, Input, Col, NavLink} from 'reactstrap';
import {useState} from 'react';
import {UserRequest} from '../api/api.users';
import CheckBox from '../common/CheckBox';
import ValidationError from '../common/ValidationError';

type Props = {
  index: number;
  initialData: UserRequest;
  onSubmit: (index: number, data: UserRequest) => void;
  validationErrors: {[key: string]: string};
};

const SignupContactForm: FC<Props> = props => {
  const {index, initialData, onSubmit, validationErrors} = props;
  const [contactData, setContactData] = useState<UserRequest>(initialData);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setContactData(contactData => ({...contactData, [name]: value}));
  }, []);

  const handleCheckBoxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {id, checked} = event.target;
    setContactData(contactData => ({...contactData, [id]: checked}));
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onSubmit(index, contactData);
    },
    [contactData, index, onSubmit]
  );

  let privacyBlurb = `Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data. This information is provided in the Privacy Policy and it is important that you read that information.

Before use of this platform, please indicate your consent to our processing of your personal data (including your name, contact details and device information) as described in the Privacy Policy.

How you can withdraw consent
Once you provide consent by checking the box, you may change your mind and withdraw consent at any time by contacting us ppehive@gmail.com but that will not affect the lawfulness of any processing carried out before you withdraw your consent.

Consent to processing Location Data
When you create an account with this platform, you are asked to give consent to processing of your Location Data (including details of my current location disclosed by GPS technology so that location-enabled Services are activated to show your location on a map).

Location Data
PPE Hive (”we") are committed to protecting your personal data and respecting your privacy.`;
  return (
    <Form className="p-4" onSubmit={handleSubmit}>
      <FormGroup className="py-2 border-0">
        <Label for="name">Name</Label>
        <Input id="name" type="text" name="name" value={contactData.name || ''} onChange={handleChange} bsSize="lg" />
        <ValidationError keys={['name']} errors={validationErrors} />
      </FormGroup>
      <FormGroup className="py-2 border-0">
        <Label for="organisation">Organisation</Label>
        <Input
          id="organisation"
          type="text"
          name="organisation"
          value={contactData.organisation || ''}
          onChange={handleChange}
          bsSize="lg"
        />
        <ValidationError keys={['organisation']} errors={validationErrors} />
      </FormGroup>
      <FormGroup className="py-2 border-0">
        <Label for="aboutme">About me</Label>
        <Input
          id="aboutme"
          type="textarea"
          rows={4}
          name="aboutme"
          value={contactData.aboutme || ''}
          onChange={handleChange}
          bsSize="lg"
        />
      </FormGroup>
      <FormGroup className="py-2 border-0">
        <Label for="phone">Phone number</Label>
        <Input id="phone" type="tel" name="phone" value={contactData.phone || ''} onChange={handleChange} bsSize="lg" />
        <ValidationError keys={['phone']} errors={validationErrors} />
      </FormGroup>
      <FormGroup className="py-2 border-0">
        <Label for="email">Email address</Label>
        <Input
          id="email"
          type="email"
          name="email"
          value={contactData.email || ''}
          onChange={handleChange}
          bsSize="lg"
        />
        <ValidationError keys={['email']} errors={validationErrors} />
      </FormGroup>
      <FormGroup className="py-2 border-0">
        <Label for="password">Password</Label>
        <Input
          id="password"
          type="password"
          name="password"
          value={contactData.password || ''}
          onChange={handleChange}
          bsSize="lg"
        />
        <ValidationError keys={['password']} errors={validationErrors} />
      </FormGroup>
      <FormGroup className="" check>
        <Label className="row mx-1 align-items-center" check>
          <Col className="pr-4">
            <NavLink href="/information/terms" target="_blank">
              I agree to the terms and conditions
            </NavLink>
          </Col>
          <CheckBox id="termsAgreed" onChange={handleCheckBoxChange} checked={contactData.termsAgreed} />
        </Label>
        <ValidationError keys={['privacyAgreed']} errors={validationErrors} />
      </FormGroup>
      <br />
      <FormGroup className="" check>
        <Label className="row mx-1 align-items-center" check>
          <Col className="pr-4">
            <NavLink href="/information/privacy" target="_blank">
              I agree to the privacy policy
            </NavLink>
          </Col>
          <CheckBox id="privacyAgreed" onChange={handleCheckBoxChange} checked={contactData.privacyAgreed} />
        </Label>
        <ValidationError keys={['termsAgreed']} errors={validationErrors} />
      </FormGroup>
      <div className="mt-3">
        {privacyBlurb.split('\n').map(p => (
          <p>{p}</p>
        ))}
      </div>
      <Button type="submit" size="lg" color="dark" className="mt-4" block>
        Next
      </Button>
    </Form>
  );
};

export default SignupContactForm;
