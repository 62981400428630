import {OrderItem, Task, TaskStatus, UID} from '../../types';

export const isOffer = (task: Task) =>
  task.dates.offeredAt && task.dates.requestedAt
    ? task.dates.offeredAt < task.dates.requestedAt
    : task.dates.offeredAt && !task.dates.requestedAt;
export const isRequest = (task: Task) =>
  task.dates.offeredAt && task.dates.requestedAt
    ? !(task.dates.offeredAt < task.dates.requestedAt)
    : !task.dates.offeredAt && task.dates.requestedAt;
export const isOpenTask = (task: Task) => (task.offer && !task.request) || (!task.offer && task.request);
export const isMatchedTask = (task: Task) => task.offer && task.request;
export const hasTransport = (task: Task) => !!task.transport;
export const isApproved = (task: Task) => !!task.dates.approvedAt;
export const isCollected = (task: Task) => !!task.dates.markedCollectedAt;
export const isDelivered = (task: Task) => !!task.dates.markedDeliveredAt;

export const isUserOffer = (task: Task, userId: UID) => task.offer?.user_id === userId;
export const isUserRequest = (task: Task, userId: UID) => task.request?.user_id === userId;
export const isUserTransport = (task: Task, userId: UID) => task.transport?.user_id === userId;
export const isUserOwner = (task: Task, userId: UID) =>
  (isOffer(task) && isUserOffer(task, userId)) || (isRequest(task) && isUserRequest(task, userId));
export const isUserInvolved = (task: Task, userId: UID) =>
  isUserOffer(task, userId) || isUserRequest(task, userId) || isUserTransport(task, userId);

/* Status helpers */
export function taskStatus(task: Task): TaskStatus {
  if (isDelivered(task)) return 'delivery_complete';
  if (isCollected(task)) return 'collection_complete';
  if (isApproved(task)) return 'approved';
  if (hasTransport(task)) return 'awaiting_approval';
  if (isMatchedTask(task)) return 'awaiting_transporter';
  if (isOpenTask(task) && isRequest(task)) return 'open_request';
  if (isOpenTask(task) && isOffer(task)) return 'open_offer';
  return 'invalid';
}

export function isValid(task: Task) {
  return taskStatus(task) !== 'invalid';
}

/* Other helpers */
export function unitsAmount(content: OrderItem) {
  const amount = content.amount || 0;
  const unit = content.item.unit || (amount === 1 ? 'unit' : 'units');
  return [amount, unit, 'of', content.item.name].join(' ');
}

export function taskHumanStatus(task: Task, userId: UID) {
  switch (taskStatus(task)) {
    case 'invalid':
      return 'Invalid task';
    case 'open_offer':
      return `Offered and waiting for a request`;
    case 'open_request':
      return `Requested and waiting for an offer`;
    case 'awaiting_transporter':
      return `Waiting for a transporter`;
    case 'awaiting_approval':
      return `Waiting for approval`;
    case 'approved':
      return `Waiting for collection`;
    case 'collection_complete':
      return `Waiting for delivery`;
    case 'delivery_complete':
      return `Delivery complete`;
  }
}
