import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react';
import {GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import {usePosition} from 'use-position';
import {Button, Input, Spinner} from 'reactstrap';
import debounce from 'lodash.debounce';

import {markerWhite} from '../images';
import {Coordinate} from '../types';
import {coordinateToLatLngLiteral, fitToLocations, geocode} from './util/mapUtil';
import './LocationPicker.css';
import Icon from './Icon';
import {render} from 'react-dom';
import {useInputValueCallback} from './hooks/useInputValueCallback';
import withGoogleMapsApiKey from './withGoogleMapsApiKey';

type PropsType = {
  googleMapsApiKey: string;
  coordinate?: Coordinate;
  onChange: (coordinate: google.maps.LatLngLiteral) => void;
  style?: CSSProperties;
  className?: string;
};

const ZERO_COORDINATE: google.maps.LatLngLiteral = {lat: 0, lng: 0};

const mapOptions: google.maps.MapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  clickableIcons: false,
};

const mapPadding = {
  top: 70,
  left: 20,
  bottom: 5,
  right: 70,
};

const LocationPicker = (props: PropsType) => {
  const {googleMapsApiKey, coordinate, onChange, style, className} = props;
  const {isLoaded: isGoogleLoaded} = useLoadScript({googleMapsApiKey});
  const position = usePosition(true);
  const [map, setMap] = useState<google.maps.Map>();
  const [myLocationButtonContainer, setMyLocationButtonContainer] = useState();
  const [searching, setSearching] = useState(false);
  const [geocodeResult, setGeocodeResult] = useState<google.maps.LatLngLiteral[] | null>(null);
  const initiaLatLng = useMemo<google.maps.LatLngLiteral | undefined>(() => {
    return coordinateToLatLngLiteral(coordinate) || undefined;
  }, [coordinate]);

  const fetchGeocode = useCallback(async (searchString: string) => {
    try {
      setSearching(true);
      const result = await geocode(searchString);
      setSearching(false);
      setGeocodeResult(result);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const debouncedGeocode = useCallback(debounce(fetchGeocode, 1000), []);

  /**
   * Adds a "my location" button as custom control of the given Map.
   */
  const addMyLocationButton = useCallback((map: google.maps.Map) => {
    const containerDiv = document.createElement('div');
    containerDiv.className = 'map-location-button-container';
    render(
      <Button className={'map-location-button'} type={'button'}>
        <Icon name={'crosshair'} color={'#666'} />
      </Button>,
      containerDiv,
      () => {
        setMyLocationButtonContainer(containerDiv);
      }
    );
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(containerDiv);
  }, []);

  const handleOnMapLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
      if (map) {
        addMyLocationButton(map);
      }
    },
    [addMyLocationButton]
  );

  /**
   * Whenever map center changes, report the new location via onChange callback.
   */
  const handleCenterChanged = useCallback(() => {
    const center = map?.getCenter().toJSON();
    if (center) {
      onChange(center);
    }
  }, [map, onChange]);

  const handleUseMyLocation = useCallback(() => {
    if (!map) {
      return;
    }
    if (!position.latitude || !position.longitude) {
      window.alert(
        'Please allow the browser to access your location. If you just did it, please wait a few seconds and try again.'
      );
      return;
    }
    map.setCenter({lat: position.latitude, lng: position.longitude});
  }, [map, position]);

  const handleSearchBoxChange = useInputValueCallback(async inputVal => {
    if (inputVal) {
      debouncedGeocode(inputVal);
    } else {
      debouncedGeocode.cancel();
      setGeocodeResult(null);
    }
  });

  useEffect(() => {
    if (googleMapsApiKey) {
      Geocode.setApiKey(googleMapsApiKey);
    }
  }, [googleMapsApiKey]);

  /**
   * When "my location" button container is available, add a click listener to it.
   */
  useEffect(() => {
    if (!myLocationButtonContainer) {
      return;
    }
    const listener = google.maps.event.addDomListener(myLocationButtonContainer, 'click', () => {
      handleUseMyLocation();
    });
    return () => google.maps.event.removeListener(listener);
  }, [myLocationButtonContainer, handleUseMyLocation]);

  const processGeocodeResults = useCallback(() => {
    if (map && geocodeResult && geocodeResult.length > 0) {
      fitToLocations(map, geocodeResult, mapPadding);
    }
  }, [map, geocodeResult]);

  useEffect(processGeocodeResults, [geocodeResult]);

  const handleMarkerClick = useCallback(
    (e: google.maps.MouseEvent) => {
      if (map) {
        map.setCenter(e.latLng);
      }
    },
    [map]
  );

  return !isGoogleLoaded ? null : (
    <div className={'LocationPicker d-flex flex-column' + (className ? ` ${className}` : '')}>
      <div
        className={'position-relative d-flex justify-content-center align-items-center'}
        style={{height: 200, ...style}}
      >
        <GoogleMap
          mapContainerClassName={'absolute-fill'}
          zoom={initiaLatLng ? 12 : 1}
          center={initiaLatLng || ZERO_COORDINATE}
          options={mapOptions}
          onLoad={handleOnMapLoad}
          onCenterChanged={handleCenterChanged}
        >
          {geocodeResult?.map(latLng => (
            <Marker key={JSON.stringify(latLng)} position={latLng} onClick={handleMarkerClick} />
          ))}
        </GoogleMap>
        <div className={'map-search-container d-flex align-items-center'}>
          <Input
            type={'text'}
            bsSize="lg"
            className={'map-search-input' + (geocodeResult && geocodeResult.length === 0 ? ' is-invalid' : '')}
            placeholder={'Type to search...'}
            onChange={handleSearchBoxChange}
          />
          {searching && <Spinner size={'sm'} />}
        </div>
        <img src={markerWhite} alt={'Location marker'} className={'position-relative'} />
      </div>
    </div>
  );
};

export default withGoogleMapsApiKey(LocationPicker);
