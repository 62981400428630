import {ChangeEvent, useCallback} from 'react';

/**
 * Returns a memoized callback that receives a change event from an input element and calls the given handler passing
 * the value received from the input. The callback is memoized **with no dependencies**, so don't use this hook if
 * your handler function has external dependencies.
 *
 * @param handler a function that will be called with the new input value as argument
 */
export function useInputValueCallback(
  handler: ((inputVal: string, inputName: string) => void) | ((inputVal: string, inputName: string) => Promise<void>)
) {
  const handlerExecutor = (event: ChangeEvent<HTMLInputElement>) => {
    handler(event.target.value, event.target.name);
  };
  return useCallback(handlerExecutor, []);
}
