import React, {FC} from 'react';
import {Task} from '../types';

const formatId = (id: string): string => {
  return `#${id.padStart(5, '0')}`;
};

type Props = {
  task: Task;
};

const TaskId: FC<Props> = props => {
  const {task} = props;
  return <small className="text-muted">{formatId(task.id)}</small>;
};

export default TaskId;
