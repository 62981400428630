import React from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from '../urls';
import URLS_OFFERS from './urls';
import {User} from '../types';
import {findOpenOffers} from '../api/api.tasks';
import TaskList from '../common/TaskList';
import withUser from '../common/withUser';

type PropsType = {
  user: User;
};

const OpenOffersPage = (props: PropsType) => {
  const {user} = props;

  return (
    <AppContainer>
      <PageHeader text="Open Offers" iconName="previous" to={URLS_OFFERS.offers} bottomMargin={false} />
      <TaskList
        className={'limit-width'}
        taskProvider={findOpenOffers}
        userId={user.id}
        moreInfo={URLS.task_info}
        noResults={'There are no open offers at the moment.'}
      />
    </AppContainer>
  );
};

export default withUser(OpenOffersPage);
