import React from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {fdm, shield} from '../images';
import URLS from './urls';
import {ListGroup} from 'reactstrap';
import ListButton from '../common/ListButton';

/* TODO: This can probably be merged with AddOfferPage */

const AddRequestPage = () => {
  return (
    <AppContainer>
      <PageHeader text="New request" iconName="previous" to={URLS.requests} bottomMargin={false} />
      <ListGroup flush className={'limit-width'}>
        <ListButton text={'Request material'} icon={fdm} to={URLS.request_add_category('material')} />
        <ListButton text={'Request PPE'} icon={shield} to={URLS.request_add_category('equipment')} />
      </ListGroup>
    </AppContainer>
  );
};

export default AddRequestPage;
