import React from 'react';
import {Redirect} from 'react-router-dom';
import URLS from '../offers/urls';

const DashboardPage = () => {
  // TODO: Redirect to the appropriate tab according to user role(s)
  return <Redirect to={URLS.offers} />;
};

export default DashboardPage;
