import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {howThisWorks1, howThisWorks2, howThisWorks3, howThisWorks4} from '../images';

const overview = `PPE Hive is a logistics platform coordinating the supply and demand of Personal Protective Equipment (PPE) in the UK. 
PPE Hive responds to increased PPE needs due to the Covid-19 crisis, with the aim of activating the capacity of grassroots efforts, independent and corporate institutions and government to adapt to these emergency needs. There is an urgent call for medical supplies to be fabricated rapidly and locally. 
PPE Hive synchronizes existing networks - larger manufacturers, material suppliers, makers, delivery providers and healthcare services for the safe/quick production and delivery of PPE to medical, health and care workers.
Our application connects demand and supply, facilitating production and delivery, while coordinating transaction logistics.
PPE Hive is led by Automated Architecture (AUAR), leaders in design and architecture innovation and experts in digital fabrication, and Calvium, leaders in the field of mobile innovation.
The functionality of the application is divided in three categories: Offers, Requests and Transport.`;

const offers = `Offers represent available materials and equipment in the community pool. Users can post a new Offer or check what the community is providing, picking what suits their needs.`;
const requests = `Requests represent the demand for materials and equipment. Users can post a new Request or check what the community is needing and provide accordingly.`;
const transport = `Transport is taking help to those who need it. Users can claim an available transport task to assist your community.`;

const HowThisWorksPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-0 limit-width text-center">
        <img src={howThisWorks1} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">How does this work?</h1>
        {overview.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
      <Container className="p-0 limit-width text-center">
        <img src={howThisWorks2} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h2 className="py-3">Offers</h2>
        {offers.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
      <Container className="p-0 limit-width text-center">
        <img src={howThisWorks3} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h2 className="py-3">Requests</h2>
        {requests.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
      <Container className="p-0 limit-width text-center">
        <img src={howThisWorks4} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h2 className="py-3">Transport</h2>
        {transport.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default HowThisWorksPage;
