import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Alert, Button, ListGroup} from 'reactstrap';
import {useDispatch} from 'react-redux';

import AppContainer from '../common/AppContainer';
import {setLoggedUser} from '../redux/user';
import {logout, getMe} from '../api/api.users';
import {User} from '../types';
import withUser from '../common/withUser';
import PageHeader from '../common/PageHeader';
import ListButton from '../common/ListButton';
import URLS from './urls';
import Icon from '../common/Icon';
import TaskMap from '../common/TaskMap';
import {createCoordinate} from '../common/util/mapUtil';
import {useLocation} from 'react-router-dom';

type PropsType = {
  user: User;
};

const ProfilePage = (props: PropsType) => {
  const {user: originalUser} = props;
  const [user, setUser] = useState(originalUser);
  const dispatch = useDispatch();
  const loggedOutRef = useRef(false);
  const coordinate = useMemo(() => createCoordinate(user.latitude, user.longitude), [user]);
  const location = useLocation<{message?: string}>();

  const infoMessage = location.state?.message;

  useEffect(() => {
    async function refreshUser() {
      try {
        const response = await getMe();
        if (response.user && !loggedOutRef.current) {
          dispatch(setLoggedUser(response.user));
          setUser(response.user);
        }
      } catch (error) {
        console.log('Error refreshing user (ignored because harmless)', error);
      }
    }
    refreshUser();
  }, [dispatch]);

  const handleLogout = useCallback(async () => {
    loggedOutRef.current = true;
    await logout();
    dispatch(setLoggedUser(null));
  }, [dispatch]);

  const renderHeader = useCallback(
    () => (
      <div className={'d-flex align-items-center'}>
        <h1 className={'m-0 flex-grow-1'}>Profile</h1>
        <Button color="link" className={'p-0 btn-small mr-3'} onClick={handleLogout}>
          <Icon name={'logout'} size={54} />
        </Button>
      </div>
    ),
    [handleLogout]
  );

  return (
    <AppContainer>
      <PageHeader iconName={'profile'} textComponent={renderHeader()} bottomMargin={false} />
      {infoMessage ? <Alert color="info">{infoMessage}</Alert> : null}
      {coordinate && <TaskMap openButton={false} fromCoordinate={coordinate} />}
      <div className="p-4 limit-width">
        <p>{user.name}</p>
        <p className={'m-0'}>{user.email}</p>
      </div>
      <ListGroup flush className={'border-top limit-width'}>
        <ListButton text="Edit Contact" to={URLS.profile_edit_contact} />
        <ListButton text="Edit Location & Address" to={URLS.profile_edit_location} />
        <ListButton text="Edit Roles" to={URLS.profile_edit_roles} />
        <ListButton text="Change Password" to={'/wip'} />
      </ListGroup>
    </AppContainer>
  );
};

export default withUser(ProfilePage);
