import React from 'react';
import {ListGroup} from 'reactstrap';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {fdm, shield} from '../images';
import URLS from './urls';
import ListButton from '../common/ListButton';

const AddOfferPage = () => {
  return (
    <AppContainer>
      <PageHeader text="New offer" iconName="previous" to={URLS.offers} bottomMargin={false} />
      <ListGroup flush className={'limit-width'}>
        <ListButton text={'Offer material'} icon={fdm} to={URLS.offer_add_category('material')} />
        <ListButton text={'Offer PPE'} icon={shield} to={URLS.offer_add_category('equipment')} />
      </ListGroup>
    </AppContainer>
  );
};

export default AddOfferPage;
