import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {InputProps} from 'reactstrap/lib/Input';
import {useUniqueId} from './hooks/useUniqueId';

type PropsType = InputProps & {
  name: string; // Make it required
  label: string;
};

/**
 * A form group with a label and an input linked.
 * @param props receives the same properties as Input with some exceptions:
 *   - `name` is required
 *   - `id` is ignored and is always the name + 'Input'
 *   - new property `label`: contains the text for the label
 * @constructor
 */
const LabelledInput = (props: PropsType) => {
  const {label, name, ...rest} = props;
  const id = useUniqueId(`${name}Input`);

  return (
    <FormGroup>
      <Label htmlFor={id} size="lg">
        {label}
      </Label>
      <Input {...rest} id={id} name={name} bsSize="lg" />
    </FormGroup>
  );
};

export default LabelledInput;
