import {User} from '../types';

export const NAME = 'user';

// ---------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------
enum ActionTypes {
  SET_LOGGED_USER = 'user/SET_LOGGED_USER',
}

// ---------------------------------------------------------------
// ACTION CREATORS
// ---------------------------------------------------------------
export function setLoggedUser(payload: User | null) {
  return {type: ActionTypes.SET_LOGGED_USER, payload};
}

// ---------------------------------------------------------------
// REDUCER
// ---------------------------------------------------------------
export interface StateType {
  loggedUser: User | null;
}

const initialState: StateType = {
  loggedUser: null,
};

function reducer(state: StateType = initialState, action: any): StateType {
  switch (action.type) {
    case ActionTypes.SET_LOGGED_USER:
      return {
        ...state,
        loggedUser: action.payload,
      };
    default:
      return state;
  }
}

// ---------------------------------------------------------------
// SELECTORS
// ---------------------------------------------------------------
export interface GlobalState {
  [NAME]: StateType;
}

export const getLoggedUser = (state: GlobalState): User | null => state[NAME].loggedUser;

export default reducer;
