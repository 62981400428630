import React from 'react';
import {ListGroup} from 'reactstrap';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import URLS from './urls';
import ListButton from '../common/ListButton';
import SimpleGuide from '../common/SimpleGuide';

const TransportPage = () => {
  return (
    <AppContainer>
      <PageHeader text="Transport" iconName="transport" bottomMargin={false} />
      <SimpleGuide
        icon="transport"
        text="Can you help by delivering material to those who need it? By claiming transport tasks, you get help where it needs to be."
      />
      <ListGroup flush className={'limit-width'}>
        <ListButton text={'Transport needed'} to={URLS.transports_open} />
        <ListButton text={'Claimed Transport'} to={URLS.transports_claimed} />
      </ListGroup>
    </AppContainer>
  );
};

export default React.memo(TransportPage);
