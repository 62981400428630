import React from 'react';
import {Button} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import './Tab.css';
import {IconName} from '../icons';
import Icon from './Icon';

type PropsType = {
  text: string;
  iconName: IconName;
  to: string;
  active: boolean;
};

const Tab = (props: PropsType) => {
  const {text, iconName, to, active} = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(to);
  };

  return (
    <Button
      className={
        'Tab mx-2 flex-grow-0 d-flex flex-column align-items-center justify-content-end rounded-lg' +
        (active ? ' active' : '')
      }
      onClick={handleClick}
    >
      <Icon name={iconName} size={32} />
      <span className={'small'}>{text}</span>
    </Button>
  );
};

export default Tab;
