import React from 'react';
import RequestsPage from './RequestsPage';
import OpenRequestsPage from './OpenRequestsPage';
import MyRequestsPage from './MyRequestsPage';
import ClaimedRequestsPage from './ClaimedRequestsPage';
import AddRequestPage from './AddRequestPage';
import CreateOrderPage from '../pages/CreateOrderPage';
import ClaimOrderPage from '../pages/ClaimOrderPage';
import URLs from './urls';
import * as route from '../common/util/routeShorthand';

export const ROUTES = [
  route.loggedIn(URLs.requests, <RequestsPage />),
  route.loggedIn(URLs.requests_open, <OpenRequestsPage />),
  route.loggedIn(URLs.requests_mine, <MyRequestsPage />),
  route.loggedIn(URLs.requests_claimed, <ClaimedRequestsPage />),
  route.loggedIn(URLs.request_add, <AddRequestPage />),
  route.loggedIn(URLs.request_add_category(), <CreateOrderPage action={'request'} />),
  route.loggedIn(URLs.request_claim(), <ClaimOrderPage action="request" />),
];
