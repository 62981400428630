import React, {useEffect, useState} from 'react';
import {Switch, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {wrapHistory} from 'oaf-react-router';
import {Helmet} from 'react-helmet';
import './App.css';
import {ROUTES} from './routes';
import {ROUTES as SIGNUP_ROUTES} from './signup/routes';
import {ROUTES as OFFERS_ROUTES} from './offers/routes';
import {ROUTES as REQUESTS_ROUTES} from './requests/routes';
import {ROUTES as TRANSPORT_ROUTES} from './transport/routes';
import {ROUTES as PROFILE_ROUTES} from './profile/routes';
import {ROUTES as ADMIN_ROUTES} from './admin/routes';

import {Provider, useDispatch} from 'react-redux';
import store, {persistor} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import {getConfig} from './api/api.common';
import {setSystemName} from './redux/app';

const ALL_ROUTES = [
  ...ROUTES,
  ...SIGNUP_ROUTES,
  ...OFFERS_ROUTES,
  ...REQUESTS_ROUTES,
  ...TRANSPORT_ROUTES,
  ...PROFILE_ROUTES,
  ...ADMIN_ROUTES,
];

/**
 * Fetch system name from server configuration, save it to redux and update app title.
 */
const SetSystemName = () => {
  const [title, setTitle] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    getConfig()
      .then(config => {
        const systemName = config.systemName;
        if (systemName) {
          dispatch(setSystemName(systemName));
          setTitle(systemName);
        }
      })
      .catch(e => console.log(e));
  }, [dispatch]);

  return title ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;
};

// Use a history object with better scroll handling
const history = createBrowserHistory();
wrapHistory(history);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<h1>Please wait..</h1>} persistor={persistor}>
        <SetSystemName />
        <Router history={history}>
          <Switch>{ALL_ROUTES}</Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
