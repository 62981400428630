import {
  Offer,
  Request,
  OrderItem,
  Task,
  Transport,
  UID,
  Product,
  Coordinate,
  RequestToMatchOffer,
  OfferToMatchRequest,
  ApiTask,
  TaskUser,
} from '../types';
import {apiFetch, apiPath} from './api.common';
import {ApiProduct, transformProduct} from './api.products';

type ApiTaskResponse =
  | {
      task: ApiTask;
      product: ApiProduct;
      users: TaskUser[];
    }
  | {
      task: null;
      product: null;
      users: null;
    };

export async function getTask(id: UID): Promise<Task | undefined> {
  const response: ApiTaskResponse = await apiFetch(apiPath`/tasks/${id}`(), {
    method: 'GET',
  });
  if (response.task == null || response.product == null) {
    return undefined;
  }
  const productsMap = new Map([response.product].map(p => [p.id, transformProduct(p)]));
  const usersMap = new Map(response.users.map(u => [u.id, u]));
  return transformTask(response.task, productsMap, usersMap);
}

type ApiTaskListResponse = {
  items: ApiTask[];
  products: ApiProduct[];
  users: TaskUser[];
};

async function findTasks(path: string): Promise<Task[]> {
  const {items, products, users} = await apiFetch<ApiTaskListResponse>(path, {
    method: 'GET',
  });
  const productsMap = new Map(products.map(p => [p.id, transformProduct(p)]));
  const usersMap = new Map(users.map(u => [u.id, u]));
  return items.map(item => transformTask(item, productsMap, usersMap));
}

export const findOpenOffers = () => findTasks('/offers/open');
export const findUserOffers = (userId: UID) => findTasks(apiPath`/offers/posted-by/${userId}`());
export const findUserOpenOffers = (userId: UID) => findTasks(apiPath`/offers/open-posted-by/${userId}`());
export const findUserClaimedOffers = (userId: UID) => findTasks(apiPath`/offers/claimed-by/${userId}`());

export const findOpenRequests = () => findTasks('/requests/open');
export const findUserRequests = (userId: UID) => findTasks(apiPath`/requests/posted-by/${userId}`());
export const findUserOpenRequests = (userId: UID) => findTasks(apiPath`/requests/open-posted-by/${userId}`());
export const findUserClaimedRequests = (userId: UID) => findTasks(apiPath`/requests/claimed-by/${userId}`());

export const findOpenTransport = () => findTasks('/carries/open');
export const findUserClaimedTransport = (userId: UID) => findTasks(apiPath`/carries/carried-by/${userId}`());

export async function createOffer(offer: Offer, contents: OrderItem) {
  const {pickupStreet, pickupArea, pickupPostcode, pickupName, pickupNotes, pickupInterval} = offer;
  const pickupLongitude = offer.pickupCoordinate?.longitude || null;
  const pickupLatitude = offer.pickupCoordinate?.latitude || null;
  const {
    amount,
    item: {id: productId},
  } = contents;
  const response = await apiFetch<{taskId: string}>(apiPath`/offers/add`(), {
    method: 'POST',
    body: JSON.stringify({
      pickupStreet,
      pickupArea,
      pickupPostcode,
      pickupName,
      pickupNotes,
      pickupInterval,
      pickupLongitude,
      pickupLatitude,
      productId,
      amount,
    }),
  });
  return response.taskId;
}

export async function createRequest(request: Request, contents: OrderItem) {
  const {deliveryStreet, deliveryArea, deliveryPostcode, deliveryName, deliveryNotes, deliveryInterval} = request;
  const deliveryLongitude = request.deliveryCoordinate?.longitude || null;
  const deliveryLatitude = request.deliveryCoordinate?.latitude || null;
  const {
    amount,
    item: {id: productId},
  } = contents;
  const response = await apiFetch<{taskId: string}>(apiPath`/requests/add`(), {
    method: 'POST',
    body: JSON.stringify({
      deliveryStreet,
      deliveryArea,
      deliveryPostcode,
      deliveryName,
      deliveryNotes,
      deliveryInterval,
      deliveryLongitude,
      deliveryLatitude,
      productId,
      amount,
    }),
  });
  return response.taskId;
}

export async function claimOffer(taskId: UID, claimRequest: RequestToMatchOffer, carryToo: boolean) {
  const {deliveryStreet, deliveryArea, deliveryPostcode, deliveryName, deliveryNotes, deliveryInterval} = claimRequest;
  const deliveryLongitude = claimRequest.deliveryCoordinate?.longitude || null;
  const deliveryLatitude = claimRequest.deliveryCoordinate?.latitude || null;
  await apiFetch(apiPath`/offers/${taskId}/claim`(), {
    method: 'POST',
    body: JSON.stringify({
      deliveryStreet,
      deliveryArea,
      deliveryPostcode,
      deliveryName,
      deliveryNotes,
      deliveryInterval,
      deliveryLongitude,
      deliveryLatitude,
      carryToo,
    }),
  });
}

export async function claimRequest(taskId: UID, claimOffer: OfferToMatchRequest, carryToo: boolean) {
  const {pickupStreet, pickupArea, pickupPostcode, pickupName, pickupNotes, pickupInterval} = claimOffer;
  const pickupLongitude = claimOffer.pickupCoordinate?.longitude || null;
  const pickupLatitude = claimOffer.pickupCoordinate?.latitude || null;
  await apiFetch(apiPath`/requests/${taskId}/claim`(), {
    method: 'POST',
    body: JSON.stringify({
      pickupStreet,
      pickupArea,
      pickupPostcode,
      pickupName,
      pickupNotes,
      pickupInterval,
      pickupLongitude,
      pickupLatitude,
      carryToo,
    }),
  });
}

export async function approveTask(taskId: UID) {
  await apiFetch(apiPath`/tasks/${taskId}/claim/approve`(), {
    method: 'POST',
  });
}

export async function rejectTask(taskId: UID) {
  await apiFetch(apiPath`/tasks/${taskId}/claim/reject`(), {
    method: 'POST',
  });
}

export async function claimTransport(taskId: UID) {
  await apiFetch(apiPath`/carries/${taskId}/claim`(), {
    method: 'POST',
    body: '{}',
  });
}

export async function transportCollected(taskId: UID) {
  await apiFetch(apiPath`/carries/${taskId}/collected`(), {
    method: 'POST',
    body: '{}',
  });
}

export async function transportDelivered(taskId: UID) {
  await apiFetch(apiPath`/carries/${taskId}/delivered`(), {
    method: 'POST',
    body: '{}',
  });
}

function toCoordinate(longitude: number | null, latitude: number | null): Coordinate | null {
  if (longitude == null || latitude == null) return null;
  return {longitude, latitude};
}

function transformTask(apiTask: ApiTask, products: Map<string, Product>, users: Map<string, TaskUser>): Task {
  const {
    id,
    offeringUser,
    requestingUser,
    carryingUser,
    pickupStreet,
    pickupArea,
    pickupPostcode,
    pickupName,
    pickupNotes,
    pickupInterval,
    pickupLongitude,
    pickupLatitude,
    deliveryStreet,
    deliveryArea,
    deliveryPostcode,
    deliveryName,
    deliveryNotes,
    deliveryInterval,
    deliveryLongitude,
    deliveryLatitude,
    productId,
    amount,
    createdAt,
    offeredAt,
    requestedAt,
    carrierAssignedAt,
    markedCollectedAt,
    markedDeliveredAt,
    approvedAt,
  } = apiTask;

  let offer: Offer | null = null;
  if (offeringUser) {
    const user = users.get(offeringUser);
    offer = {
      user_id: offeringUser,
      user,
      pickupStreet,
      pickupArea: pickupArea || 'invalid',
      pickupPostcode: pickupPostcode || 'invalid',
      pickupInterval,
      pickupName,
      pickupNotes,
      pickupCoordinate: toCoordinate(pickupLongitude, pickupLatitude),
    };
  }

  let request: Request | null = null;
  if (requestingUser) {
    const user = users.get(requestingUser);
    request = {
      user_id: requestingUser,
      user,
      deliveryStreet,
      deliveryArea: deliveryArea || 'invalid',
      deliveryPostcode: deliveryPostcode || 'invalid',
      deliveryInterval,
      deliveryName,
      deliveryNotes,
      deliveryCoordinate: toCoordinate(deliveryLongitude, deliveryLatitude),
    };
  }

  let transport: Transport | null = null;
  if (carryingUser != null) {
    const user = users.get(carryingUser);
    transport = {
      user_id: carryingUser,
      user,
    };
  }

  const product = products.get(productId);
  if (product == null) throw new Error(`Task ${id} has product id ${productId} missing from products map`);
  const contents = {
    item: product,
    amount,
  };

  return {
    id,
    offer,
    request,
    transport,
    contents,
    dates: {
      createdAt,
      offeredAt,
      requestedAt,
      carrierAssignedAt,
      markedCollectedAt,
      markedDeliveredAt,
      approvedAt,
    },
  };
}
