import React from 'react';
import TransportPage from './TransportPage';
import OpenTransportPage from './OpenTransportPage';
import ClaimedTransportsPage from './ClaimedTransportsPage';
import URLS from './urls';
import * as route from '../common/util/routeShorthand';
import ClaimOrderPage from '../pages/ClaimOrderPage';

export const ROUTES = [
  route.loggedIn(URLS.transport, <TransportPage />),
  route.loggedIn(URLS.transports_open, <OpenTransportPage />),
  route.loggedIn(URLS.transports_claimed, <ClaimedTransportsPage />),
  route.loggedIn(URLS.transport_claim(), <ClaimOrderPage action="transport" />),
];
