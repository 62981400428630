import {Assurance, UID, User} from '../types';
import {apiFetch} from './api.common';

export type UserRequest = Partial<
  Pick<
    User,
    | 'email'
    | 'name'
    | 'organisation'
    | 'aboutme'
    | 'street'
    | 'area'
    | 'postcode'
    | 'longitude'
    | 'latitude'
    | 'phone'
    | 'roles'
    | 'privacyAgreed'
    | 'termsAgreed'
  > & {
    password: string;
  }
>;

export async function login(username: string, password: String): Promise<User> {
  if (!password || !password.length) {
    throw new Error('Invalid password');
  }

  const response = await apiFetch<User>('/login', {
    method: 'POST',
    body: JSON.stringify({
      email: username,
      password,
    }),
  });
  console.log('Login succeeded', response);
  return response;
}

export async function logout(): Promise<void> {
  await apiFetch<any>('/logout', {
    method: 'POST',
  });
}

export async function getMe(): Promise<{user: User | null}> {
  return apiFetch('/account/me', {
    method: 'GET',
  });
}

export async function createUser(userReq: UserRequest): Promise<{user: User}> {
  return await apiFetch<{user: User}>('/account/create', {
    method: 'POST',
    body: JSON.stringify(userReq),
  });
}

export async function updateUser(user: User): Promise<{user: User}> {
  return await apiFetch<{user: User}>('/account/update', {
    method: 'PUT',
    body: JSON.stringify(user),
  });
}

export async function listAssurances(): Promise<{[role: string]: Assurance[]}> {
  const assurances = await apiFetch<{items: Assurance[]}>('/assurances/list', {
    method: 'GET',
  });
  const result: {[role: string]: Assurance[]} = {};
  for (const item of assurances.items) {
    if (!result[item.role]) {
      result[item.role] = [];
    }
    result[item.role].push(item);
  }
  return result;
}

export async function getUser(id: UID): Promise<{user: User | null}> {
  return apiFetch(`/users/${id}`, {
    method: 'GET',
  });
}

export async function verifyUser(id: UID): Promise<{user: User | null}> {
  return apiFetch(`/users/${id}/verify`, {
    method: 'POST',
  });
}

export async function unverifyUser(id: UID): Promise<{user: User | null}> {
  return apiFetch(`/users/${id}/unverify`, {
    method: 'POST',
  });
}
