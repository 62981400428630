import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const InfoIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke={color}>
        <circle cx="20.5" cy="20.5" r="15.5" strokeWidth="1.1" />
        <circle cx="20.5" cy="12.5" fill={color} r="1.5" />
        <path d="m20.5 29.5v-13.487767" stroke={color} strokeWidth="2.2" />
      </g>
    </svg>
  );
};
export default InfoIcon;
