import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const ReloadIcon: FC<Props> = props => {
  const {className, size = 54, color = 'black'} = props;
  return (
    <svg
      className={className}
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <path d="M90.5,50.2C90.6,72.6,72.4,90,50.1,91c0,0-0.1,0-0.1,0C27.7,91,9.5,72.7,9.5,50.4  c0-22.4,18.1-40.7,40.5-40.7l0.1,5.9V0.8l15.8,14.8L50,30.4v-8.9c-8,0-14.9,3-20.3,8.4c-5.4,5.4-8.4,12.6-8.4,20.3  c0,7.7,3,14.9,8.4,20.3C35.2,76,42.4,79,50,79c0,0,0,0,0.1,0c15.8,0,28.7-13,28.6-28.8c0-7.7-3-14.9-8.4-20.3l8.4-8.4  C86.3,29.2,90.5,39.4,90.5,50.2z" />
    </svg>
  );
};

export default ReloadIcon;
