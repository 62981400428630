import React, {useEffect, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {Task, UID, User} from '../types';
import ErrorFree from '../common/ErrorFree';
import ConditionalSpinner from '../common/ConditionalSpinner';
import {approveTask, getTask, rejectTask, transportCollected, transportDelivered} from '../api/api.tasks';
import {
  isUserOffer,
  isUserOwner,
  isUserRequest,
  isUserTransport,
  isValid,
  taskStatus,
  unitsAmount,
} from '../common/util/taskUtil';
import {Button, Alert, Container, ButtonProps, ButtonGroup} from 'reactstrap';
import TaskMap from '../common/TaskMap';
import URLS from '../urls';
import URLS_OFFERS from '../offers/urls';
import URLS_REQUESTS from '../requests/urls';
import URLS_TRANSPORT from '../transport/urls';
import TaskTitle from '../common/TaskTitle';
import withUser from '../common/withUser';
import TaskDetail from '../common/TaskDetail';

type PropsType = {
  user: User;
};

/**
 * A page that shows information about a task. Data is displayed according to task status. Action button also depends
 * on task status.
 *
 * The back button in page header points to the URL provided via `referrer` field in location state, e.g. by navigating
 * to this page with `history.push(..., {referrer: ...})`. If a referrer is not set, the back button points to
 * `/dashboard`.
 */
const TaskPage = (props: PropsType) => {
  const {taskId} = useParams<{taskId: string}>();
  const {user} = props;
  const history = useHistory();
  const location = useLocation<{referrer?: string; message?: string; initialTask?: Task}>();
  const [task, setTask] = useState<Task | undefined>(location.state?.initialTask);
  const [error, setError] = useState<string | null>(null);

  const goBackUrl = location.state?.referrer ?? URLS.dashboard;
  const infoMessage = location.state?.message;

  useEffect(() => {
    if (taskId === undefined) {
      setError('Task ID is required');
      return;
    }

    const doAsync = async () => {
      try {
        const task = await getTask(taskId);
        if (!task) {
          setError(`Task with ID ${taskId} not found`);
        } else if (!isValid(task)) {
          setError(`Task ${taskId} is invalid`);
        } else {
          setTask(task);
        }
      } catch (e) {
        setError(e.message);
      }
    };
    doAsync();
  }, [taskId]);

  return (
    <AppContainer>
      <ErrorFree error={error}>
        <ConditionalSpinner spinner={!task}>
          <PageHeader
            textComponent={task && <TaskTitle task={task} userId={user.id} />}
            bottomMargin={!!error}
            iconName="previous"
            to={goBackUrl}
          />
          {infoMessage ? <Alert color="info">{infoMessage}</Alert> : null}
          <Container className="d-flex flex-column px-4 limit-width">
            {task && (
              <TaskMap
                openButton={true}
                fromCoordinate={task?.offer?.pickupCoordinate}
                toCoordinate={task?.request?.deliveryCoordinate}
              />
            )}
            {task && renderTaskActionButtons(task, history, user.id)}
            {task && <TaskDetail task={task} userId={user.id} />}
          </Container>
        </ConditionalSpinner>
      </ErrorFree>
    </AppContainer>
  );
};

export default withUser(TaskPage);

function renderTaskActionButtons(task: Task, history: any, userId: UID) {
  const buttons: Array<ButtonProps> = [];
  switch (taskStatus(task)) {
    case 'invalid':
    case 'delivery_complete':
      return null;
    case 'open_offer':
      if (isUserOwner(task, userId)) {
        return null;
      }
      buttons.push({
        children: `Request ${unitsAmount(task.contents)}`,
        onClick: () => history.push(URLS_OFFERS.offer_claim(task.id), {initialTask: task}),
      });
      break;
    case 'open_request':
      if (isUserOwner(task, userId)) {
        return null;
      }
      buttons.push({
        children: `Offer ${unitsAmount(task.contents)}`,
        onClick: () => history.push(URLS_REQUESTS.request_claim(task.id), {initialTask: task}),
      });
      break;
    case 'awaiting_transporter':
      buttons.push({
        children: 'Offer transportation',
        onClick: () => history.push(URLS_TRANSPORT.transport_claim(task.id), {initialTask: task}),
      });
      break;
    case 'awaiting_approval':
      if (!isUserOwner(task, userId)) {
        return null;
      }
      buttons.push({
        children: 'Reject',
        color: 'warning',
        onClick: async () => {
          const confirmed = window.confirm('Please confirm your rejection');
          if (!confirmed) return;
          try {
            await rejectTask(task.id);
            window.location.replace(URLS.task_info(task.id));
          } catch (e) {
            // TODO this error message should be in the user friendly error interface, rather than an alert
            window.alert('A problem occurred when attempting to mark as approved');
          }
        },
      });
      buttons.push({
        children: 'Approve',
        color: 'success',
        onClick: async () => {
          const confirmed = window.confirm('Please confirm your approval');
          if (!confirmed) return;
          try {
            await approveTask(task.id);
            window.location.replace(URLS.task_info(task.id));
          } catch (e) {
            // TODO this error message should be in the user friendly error interface, rather than an alert
            window.alert('A problem occurred when attempting to mark as approved');
          }
        },
      });
      break;
    case 'approved':
      if (!isUserTransport(task, userId) && !isUserOffer(task, userId)) {
        return null;
      }
      buttons.push({
        children: 'Mark item as collected',
        onClick: async () => {
          const confirmed = window.confirm('Please confirm this item has been collected');
          if (!confirmed) return;
          try {
            await transportCollected(task.id);
            window.location.replace(URLS.task_info(task.id));
          } catch (e) {
            // TODO this error message should be in the user friendly error interface, rather than an alert
            window.alert('A problem occurred when attempting to mark as collected');
          }
        },
      });
      break;
    case 'collection_complete':
      if (!isUserTransport(task, userId) && !isUserRequest(task, userId)) {
        return null;
      }
      buttons.push({
        children: 'Mark item as delivered',
        onClick: async () => {
          const confirmed = window.confirm('Please confirm this item has been delivered');
          if (!confirmed) return;
          try {
            await transportDelivered(task.id);
            window.location.replace(URLS.task_info(task.id));
          } catch (e) {
            // TODO this error message should be in the user friendly error interface, rather than an alert
            window.alert('A problem occurred when attempting to mark as delivered');
          }
        },
      });
      break;
  }

  if (buttons.length === 0) return null;

  return (
    <ButtonGroup className="mt-3">
      {buttons.map((props, i) => (
        <Button key={(props.children || i).toString()} className="button-block" color={'dark'} size="lg" {...props} />
      ))}
    </ButtonGroup>
  );
}
