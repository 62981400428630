import React from 'react';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import InformationPage from './pages/InformationPage';
import TaskPage from './pages/TaskPage';
import URLS from './urls';
import * as route from './common/util/routeShorthand';
import WipPage from './pages/WipPage';
import BestPractice from './pages/BestPractice';
import BestPracticeCollecting from './pages/BestPracticeCollecting';
import BestPracticeDelivering from './pages/BestPracticeDelivering';
import BestPracticeOffering from './pages/BestPracticeOffering';
import BestPracticeRequesting from './pages/BestPracticeRequesting';
import BestPracticeTransporting from './pages/BestPracticeTransporting';
import HowThisWorksPage from './pages/HowThisWorksPage';
import AboutProjectPage from './pages/AboutProjectPage';
import VerifyHealthWorkers from './pages/VerifyHealthWorkers';
import CreditsPage from './pages/CreditsPage';
import HowCanIHelpPage from './pages/HowCanIHelpPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Resources from './pages/Resources';

export const ROUTES = [
  route.loggedOut(URLS.home, <HomePage />),
  route.loggedOut(URLS.login, <LoginPage />),
  route.always(URLS.information, <InformationPage />),
  route.always(URLS.about, <AboutProjectPage />),
  route.always(URLS.how, <HowThisWorksPage />),
  route.always(URLS.help, <HowCanIHelpPage />),
  route.always(URLS.bestPractice, <BestPractice />),
  route.always(URLS.bestPracticeOffering, <BestPracticeOffering />),
  route.always(URLS.bestPracticeRequesting, <BestPracticeRequesting />),
  route.always(URLS.bestPracticeTransporting, <BestPracticeTransporting />),
  route.always(URLS.bestPracticeCollecting, <BestPracticeCollecting />),
  route.always(URLS.bestPracticeDelivering, <BestPracticeDelivering />),
  route.always(URLS.verifyHealthWorkers, <VerifyHealthWorkers />),
  route.always(URLS.resources, <Resources />),
  route.always(URLS.wip, <WipPage />),
  route.always(URLS.credits, <CreditsPage />),
  route.always(URLS.terms, <TermsAndConditionsPage />),
  route.always(URLS.privacy, <PrivacyPolicyPage />),
  route.loggedIn(URLS.dashboard, <DashboardPage />),
  route.loggedIn(URLS.task_info(), <TaskPage />),
];
