import React, {DetailedHTMLProps, InputHTMLAttributes, useCallback, useRef} from 'react';
import {Button, Input} from 'reactstrap';
import {minus, plus} from '../images';
import {useInputValueCallback} from './hooks/useInputValueCallback';
import './SideButtonsInput.css';

type PropsType = Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> & {
  onChange: (value: string) => void;
};

const SideButtonsInput = (props: PropsType) => {
  const {value, onChange} = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useInputValueCallback(inputVal => {
    onChange(inputVal);
  });

  const handleMinusClick = useCallback(() => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    const oldValue = parseInt(input.value) || 0;
    const newValue = Math.max(1, oldValue - 1).toString();
    onChange(newValue);
  }, [onChange]);

  const handlePlusClick = useCallback(() => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    const oldValue = parseInt(input.value) || 0;
    const newValue = Math.max(1, oldValue + 1).toString();
    onChange(newValue);
  }, [onChange]);

  return (
    <div className={'SideButtonsInput position-relative d-flex justify-content-between'}>
      <div className={'absolute-fill d-flex align-items-center'}>
        <Input
          value={value}
          innerRef={inputRef}
          className={['form-control px-5 mx-5 text-center', props.className].join(' ')}
          min={1}
          onChange={handleChange}
          bsSize="lg"
        />
      </div>
      <Button
        className={'rounded-circle p-0 btn-small position-relative'}
        color={'dark'}
        onClick={handleMinusClick}
        disabled={!value || value <= 1}
      >
        <img src={minus} alt={'Decrement 1'} />
      </Button>
      <Button className={'rounded-circle p-0 btn-small position-relative'} color={'dark'} onClick={handlePlusClick}>
        <img src={plus} alt={'Increment 1'} />
      </Button>
    </div>
  );
};

export default SideButtonsInput;
