import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {bptransporting} from '../images';

const about = `Carefully read the instructions for collection delivery, avoiding collecting and delivering the packages in the wrong place or time.
Only claim a Transport task that you can fulfil in the specified timeframe and location. If you have a question, get in touch with the sender or recipient! 
Working together is a fundamental part of our community. 
If you cannot proceed with the Transport task anymore, remember to cancel it on PPE Hive, so the task can be claimed by someone else. 
After collection and delivery, remember to mark the package as collected on PPE Hive so every party is notified.`;

const BestPracticeTransporting: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.bestPractice} />
      <Container className="p-0 limit-width text-center">
        <img src={bptransporting} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">Best Practice for transporting</h1>
        {about.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default BestPracticeTransporting;
