import {ButtonGroup, Navbar} from 'reactstrap';
import URLS from '../urls';
import {URLS as URLS_SIGNUP} from '../signup/routes';
import URLS_OFFERS from '../offers/urls';
import URLS_REQUESTS from '../requests/urls';
import URLS_TRANSPORT from '../transport/urls';
import URLS_PROFILE from '../profile/urls';

import React, {useCallback, useMemo} from 'react';
import {User} from '../types';
import {useLocation} from 'react-router-dom';
import Tab from './Tab';
import './UserTabBar.css';
import {IconName} from '../icons';

type PropsType = {
  user: User | null;
};

const UserTabBar = (props: PropsType) => {
  const {user} = props;
  const location = useLocation();

  const renderTab = useCallback(
    (text: string, iconName: IconName, url: string) => (
      <Tab text={text} iconName={iconName} to={url} active={url === location.pathname} />
    ),
    [location]
  );

  const renderLoggedOutTabs = useMemo(
    () => (
      <>
        {renderTab('Information', 'info', URLS.information)}
        {renderTab('Log in', 'profile', URLS.login)}
        {renderTab('Sign up', 'profile', URLS_SIGNUP.signup)}
      </>
    ),
    [renderTab]
  );

  const renderLoggedInTabs = useMemo(
    () => (
      <>
        {renderTab('Offers', 'offer', URLS_OFFERS.offers)}
        {renderTab('Requests', 'request', URLS_REQUESTS.requests)}
        {renderTab('Transport', 'transport', URLS_TRANSPORT.transport)}
        {renderTab('Profile', 'profile', URLS_PROFILE.profile)}
        {renderTab('Info', 'info', URLS.information)}
      </>
    ),
    [renderTab]
  );

  return (
    <Navbar light fixed="bottom" className={'UserTabBar py-2 px-0 border-top'}>
      <ButtonGroup className={'expand justify-content-between'}>
        {user === null ? renderLoggedOutTabs : renderLoggedInTabs}
      </ButtonGroup>
    </Navbar>
  );
};

export default UserTabBar;
