import React from 'react';
import {Route} from 'react-router-dom';
import LoggedOutRoute from '../LoggedOutRoute';
import LoggedInRoute from '../LoggedInRoute';
import AdminRoute from '../AdminRoute';

export const loggedOut = (path: string, child: React.ReactElement) => (
  <LoggedOutRoute path={path} key={path} exact>
    {child}
  </LoggedOutRoute>
);
export const loggedIn = (path: string, child: React.ReactElement) => (
  <LoggedInRoute path={path} key={path} exact>
    {child}
  </LoggedInRoute>
);
export const always = (path: string, child: React.ReactElement) => (
  <Route path={path} key={path} exact>
    {child}
  </Route>
);
export const admin = (path: string, child: React.ReactElement) => (
  <AdminRoute path={path} key={path} exact>
    {child}
  </AdminRoute>
);
