import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const OtherIcon: FC<Props> = props => {
  const {className, size = 32, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
      <polygon
        fill={color}
        fillRule="nonzero"
        points="26.5515 4 26.551 13.904 36.808 13.904 36.808 26.904 26.551 26.904 26.5515 36.808 13.5515 36.808 13.551 26.904 4 26.904 4 13.904 13.551 13.904 13.5515 4"
      />
    </svg>
  );
};
export default OtherIcon;
