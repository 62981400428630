import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import AppContainer from '../common/AppContainer';
import PageHeader from '../common/PageHeader';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {Task, RequestToMatchOffer, OfferToMatchRequest, Coordinate, User} from '../types';
import {Button, Form, FormGroup, FormText, Input, Label, Modal, Col, Row, Container} from 'reactstrap';
import {plus} from '../images';
import URLS from '../urls';
import URLS_OFFERS from '../offers/urls';
import URLS_REQUESTS from '../requests/urls';
import URLS_TRANSPORT from '../transport/urls';
import {useInputValueCallback} from '../common/hooks/useInputValueCallback';
import ErrorFree from '../common/ErrorFree';
import ConditionalSpinner from '../common/ConditionalSpinner';
import {NBSP, SEP} from '../common/util/chars';
import {getTask, claimOffer, claimRequest, claimTransport} from '../api/api.tasks';
import TaskMap from '../common/TaskMap';
import {createCoordinate, coordinateToString, Address, formatAddress} from '../common/util/mapUtil';
import {unitsAmount} from '../common/util/taskUtil';
import {FC} from 'react';
import withUser from '../common/withUser';
import EditLocationModal from '../common/EditLocationModal';
import ValidationError, {mapValidationErrors} from '../common/ValidationError';

type AddressProps = {
  title: string;
  street?: string | null;
  area?: string | null;
  postcode?: string | null;
  coordinate?: Coordinate | null;
};

const AddressContainer: FC<AddressProps> = React.memo(props => {
  const {title, street, area, postcode, coordinate} = props;
  return (
    <Container>
      <Row className="px-2">
        <Col className="px-3 border-right">
          <p className="m-0">{title}</p>
          <address className="m-0">{[street, area, postcode].filter(Boolean).join('\n')}</address>
        </Col>
        {coordinate && (
          <Col className="d-flex px-3 align-items-center">
            <Button
              tag="a"
              href={`https://www.google.com/maps/search/?api=1&query=${coordinateToString(coordinate)}`}
              block
            >
              Show on map
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
});

type PropsType = {
  action: 'offer' | 'request' | 'transport';
  user: User;
};

const ClaimOfferPage = (props: PropsType) => {
  const {action, user} = props;
  const {taskId} = useParams<{taskId: string}>();
  const history = useHistory();
  const location = useLocation<{task?: Task}>();

  const [fetchError, setFetchError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [task, setTask] = useState<Task | undefined>(location.state.task);

  const [carryToo, setCarryToo] = useState(false);
  const [address, setAddress] = useState<Address>({street: user.street, area: user.area, postcode: user.postcode});
  const [coordinate, setCoordinate] = useState<Coordinate | undefined>(createCoordinate(user.latitude, user.longitude));
  const [contact, setContact] = useState<string>(user.name || '');
  const [notes, setNotes] = useState<string>('');
  const [intervals, setIntervals] = useState<string[]>(['']);

  const isOffer = action === 'offer';
  const isRequest = action === 'request';
  const isTransport = action === 'transport';

  useEffect(() => {
    const doAsync = async () => {
      try {
        setFetchError(null);
        const task = await getTask(taskId);
        setTask(task);
      } catch (error) {
        console.log(error);
        setFetchError(`Error getting data from the server :( ${error.message}`);
      }
    };
    doAsync();
  }, [taskId]);

  const handleIntervalChange = useInputValueCallback((inputVal, inputName) => {
    const index = parseInt(inputName.split(SEP)[1]);
    setIntervals(prevIntervals => {
      const newIntervals = [...prevIntervals];
      newIntervals[index] = inputVal;
      return newIntervals;
    });
  });

  const handleAddInterval = useCallback(() => {
    setIntervals(prevState => {
      const newIntervals = [...prevState];
      newIntervals.push('');
      return newIntervals;
    });
  }, []);

  const handleCarryTooChange = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement).checked;
      setCarryToo(value);
    },
    [setCarryToo]
  );

  const handleContactChange = useInputValueCallback(inputVal => {
    setContact(inputVal);
  });

  const toggleLocationModal = useCallback(() => {
    setShowLocationModal(prevState => !prevState);
  }, []);

  const handleLocationChange = useCallback(
    result => {
      setCoordinate(result.coordinate);
      setAddress({street: result.street, area: result.area, postcode: result.postcode});
      toggleLocationModal();
    },
    [toggleLocationModal]
  );

  const handleNotesChange = useInputValueCallback(inputVal => {
    setNotes(inputVal);
  });

  const handleAddOrder = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setValidationErrors({});

      if (isRequest) {
        const claim: OfferToMatchRequest = {
          pickupStreet: address.street || '',
          pickupArea: address.area || '',
          pickupPostcode: address.postcode || '',
          pickupName: contact,
          pickupNotes: notes,
          pickupInterval: intervals.join('\n'),
          pickupCoordinate: coordinate || null,
        };

        try {
          await claimRequest(taskId, claim, carryToo);
          history.push(URLS.task_info(taskId), {
            message: 'You have offered to fulfil this request. You will now see this in the Offers tab',
            referrer: URLS_REQUESTS.requests,
          });
        } catch (e) {
          console.error(e);
          setValidationErrors(mapValidationErrors(e?.body?.errors || []));
          setSubmitError('Your offer could not be sent. ' + e.message);
          window.scrollTo(0, 0);
        }
      } else if (isOffer) {
        const claim: RequestToMatchOffer = {
          deliveryStreet: address.street || '',
          deliveryArea: address.area || '',
          deliveryPostcode: address.postcode || '',
          deliveryName: contact,
          deliveryNotes: notes,
          deliveryInterval: intervals.join('\n'),
          deliveryCoordinate: coordinate || null,
        };

        try {
          await claimOffer(taskId, claim, carryToo);
          history.push(URLS.task_info(taskId), {
            message: 'You have requested this offer. You will now see this in the Request tab',
            referrer: URLS_OFFERS.offers,
          });
        } catch (e) {
          console.error(e);
          setValidationErrors(mapValidationErrors(e?.body?.errors || []));
          setSubmitError('Your request could not be sent. ' + e.message);
          window.scrollTo(0, 0);
        }
      } else if (isTransport) {
        try {
          await claimTransport(taskId);
          history.push(URLS.task_info(taskId), {
            message: 'You have offered to transport this request',
            referrer: URLS_TRANSPORT.transport,
          });
        } catch (e) {
          console.error(e);
          setValidationErrors(mapValidationErrors(e?.body?.errors || []));
          setSubmitError('Your transport offer could not be sent. ' + e.message);
          window.scrollTo(0, 0);
        }
      }
    },
    [isRequest, isOffer, isTransport, address, contact, notes, intervals, coordinate, taskId, carryToo, history]
  );

  const title = task
    ? `${isRequest ? 'Offer' : isTransport ? 'Transport' : 'Request'} ${unitsAmount(task?.contents)}`
    : '';
  const backUrl = URLS.task_info(taskId);
  const transferTitle = isRequest ? 'Available collection' : 'Available delivery';
  const transferDescription = isRequest
    ? 'When can other people pick these units up from you?'
    : 'When can other people deliver these units up to you?';
  const contactTitle = isRequest ? 'Person to collect from' : 'Person to deliver to';
  const contactDescription = isRequest ? 'Who can people pick this up from?' : 'Who can people deliver this to?';
  const addressTitle = isRequest ? 'Collection address' : 'Delivery address';
  const notesTitle = 'Additional notes';
  const notesDescription = isRequest
    ? 'These will be seen by whoever responds to your offer.'
    : 'These will be seen by whoever responds to your request.';
  const carryTooTitle = `I can ${isRequest ? `deliver` : isOffer ? `collect` : ``} this item myself`;
  const carryTooDescription =
    'Please tick this if you are going to transport the material yourself. If not, we will open a transport request to try to find someone who can move it for you.';
  const validationPrefix = isRequest ? 'Collection' : isTransport ? 'Transport' : 'Delivery';

  const renderFormFields = () => {
    if (isTransport) return null;
    return (
      <>
        <FormGroup>
          {isOffer && (
            <AddressContainer
              title={'Collection from :'}
              street={task?.offer?.pickupStreet}
              area={task?.offer?.pickupArea}
              postcode={task?.offer?.pickupPostcode}
              coordinate={task?.offer?.pickupCoordinate}
            />
          )}
          {isRequest && (
            <AddressContainer
              title={'Delivery to :'}
              street={task?.request?.deliveryStreet}
              area={task?.request?.deliveryArea}
              postcode={task?.request?.deliveryPostcode}
              coordinate={task?.request?.deliveryCoordinate}
            />
          )}
        </FormGroup>
        <FormGroup>
          <FormGroup check>
            <Label check>
              <ValidationError prefix={validationPrefix} keys={['carryToo']} errors={validationErrors} />
              <Input
                type="checkbox"
                className="mx-2"
                name="carryToo"
                checked={carryToo}
                onChange={handleCarryTooChange}
              />{' '}
              <span className="mx-5">{carryTooTitle}</span>
            </Label>
            <FormText className="px-2 py-4">{carryTooDescription}</FormText>
          </FormGroup>
        </FormGroup>

        <FormGroup className="p-3">
          <Label>{transferTitle}</Label>
          <FormText>{transferDescription}</FormText>
          <ValidationError
            prefix={validationPrefix}
            keys={['pickupInterval', 'deliveryInterval']}
            errors={validationErrors}
          />
          {intervals.map((e, index) => (
            <div key={index} className={'d-flex align-items-center'}>
              <Input
                type={'text'}
                className={'my-2'}
                name={`interval${SEP}${index}`}
                value={intervals[index]}
                onChange={handleIntervalChange}
                bsSize="lg"
              />
              <span style={{width: 77}}>
                {index === intervals.length - 1 ? (
                  <Button className={'rounded-circle p-0 btn-small ml-3'} color={'dark'} onClick={handleAddInterval}>
                    <img src={plus} alt={'Add time slot'} />
                  </Button>
                ) : (
                  NBSP
                )}
              </span>
            </div>
          ))}
        </FormGroup>

        <FormGroup className="p-3">
          <Label for="contact">{contactTitle}</Label>
          <FormText>{contactDescription}</FormText>
          <ValidationError prefix={validationPrefix} keys={['pickupName', 'deliveryName']} errors={validationErrors} />
          <Input
            id="contact"
            type={'text'}
            name={'contact'}
            value={contact}
            onChange={handleContactChange}
            bsSize="lg"
          />
        </FormGroup>

        <FormGroup className="p-3">
          <Label for="address">{addressTitle}</Label>
          <ValidationError
            prefix={validationPrefix}
            keys={[
              'pickupStreet',
              'pickupArea',
              'pickupPostcode',
              'deliveryStreet',
              'deliveryArea',
              'deliveryPostcode',
              'pickupLongitude',
              'deliveryLongitude',
              'pickupLatitude',
              'deliveryLatitude',
            ]}
            errors={validationErrors}
          />
          {coordinate && <TaskMap openButton={false} fromCoordinate={coordinate} style={{height: 150}} />}
          <p className={'d-flex justify-content-between align-items-center mt-3'}>
            <FormText className={address ? '' : 'font-italic'}>
              {formatAddress(address) || 'Please choose an address'}
            </FormText>
            <Button type={'button'} color={'dark'} onClick={toggleLocationModal}>
              Edit
            </Button>
          </p>
          <Modal isOpen={showLocationModal} toggle={toggleLocationModal}>
            <EditLocationModal
              coordinate={coordinate}
              street={address.street}
              area={address.area}
              postcode={address.postcode}
              onCancel={toggleLocationModal}
              onResult={handleLocationChange}
            />
          </Modal>
        </FormGroup>

        <FormGroup className="p-3">
          <Label for="notes">{notesTitle}</Label>
          <FormText>{notesDescription}</FormText>
          <ValidationError
            prefix={validationPrefix}
            keys={['pickupNotes', 'deliveryNotes']}
            errors={validationErrors}
          />
          <Input
            id="notes"
            type={'textarea'}
            rows={3}
            name={'notes'}
            value={notes}
            onChange={handleNotesChange}
            bsSize="lg"
          />
        </FormGroup>
      </>
    );
  };

  return (
    <AppContainer>
      <PageHeader text={title} iconName="previous" to={backUrl} />
      <ErrorFree error={submitError} />
      <ErrorFree error={fetchError}>
        <ConditionalSpinner spinner={!task}>
          <Form className={'d-flex flex-column limit-width'} onSubmit={handleAddOrder}>
            {renderFormFields()}
            <FormGroup className="p-3 border-0">
              <Button type={'submit'} color={'dark'} size="lg" block>
                Submit
              </Button>
            </FormGroup>
          </Form>
        </ConditionalSpinner>
      </ErrorFree>
    </AppContainer>
  );
};

export default React.memo(withUser(ClaimOfferPage));
