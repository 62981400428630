import React from 'react';
import {Product, Task, UID} from '../types';
import {isUserOffer, isUserRequest, unitsAmount} from './util/taskUtil';
import './TaskTitle.css';
import TaskStatusIndicator from './TaskStatusIndicator';
import {Badge, Col} from 'reactstrap';
import TaskId from './TaskId';

type PropsType = {
  task: Task;
  userId: UID;
};

const TaskTitle = (props: PropsType) => {
  const {task, userId} = props;
  const title = unitsAmount(task.contents);
  const relation = isUserOffer(task, userId) ? 'Offered by you' : isUserRequest(task, userId) ? 'Requested by you' : '';
  return (
    <div className={'TaskTitle d-flex align-items-center'}>
      <span className={'TaskTitleImageContainer left-column text-center mr-3'}>
        <ProductImage product={task.contents.item} />
      </span>
      <Col>
        <h1 className="m-0">{title}</h1>
        <TaskStatusIndicator task={task} />
        <p>
          {relation.length > 0 && (
            <Badge color="secondary" className="mr-2">
              {relation}
            </Badge>
          )}
          <TaskId task={task} />
        </p>
      </Col>
    </div>
  );
};

const ProductImage = React.memo(function ProductImage({product}: {product: Product}) {
  const {image, name} = product;
  if (!image) {
    return <div style={{width: '21px'}} />;
  }
  return <img src={image.src} width={21} alt={name} />;
});

export default React.memo(TaskTitle);
