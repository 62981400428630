import React, {useCallback, FC} from 'react';
import {Button} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {IconName} from '../icons';
import Icon from './Icon';
import './BrandHeader.css';
import {useOurSelector} from '../redux/rootReducer';
import {getSystemName} from '../redux/app';

type Props = {
  iconName?: IconName;
  to?: string;
};

const BrandHeader: FC<Props> = props => {
  const {iconName, to} = props;
  const history = useHistory();
  const systemName = useOurSelector(getSystemName);

  const handleClick = useCallback(() => {
    if (to !== undefined) {
      history.push(to);
    }
  }, [history, to]);

  return (
    <div className="BrandHeader d-flex align-items-center p-4">
      <Button color="link" className={'p-0 btn-small mr-4'} onClick={handleClick}>
        {iconName && <Icon name={iconName} size={54} color="white" />}
      </Button>
      <h1 className={'text-capitalize m-0'}>{systemName}</h1>
    </div>
  );
};

export default BrandHeader;
