import React, {FC} from 'react';
import AppContainer from '../common/AppContainer';
import BrandHeader from '../common/BrandHeader';
import URLS from '../urls';
import {Container} from 'reactstrap';
import {aboutProject} from '../images';

const about = `PPE Hive is a logistics platform coordinating the supply and demand of Personal Protective Equipment (PPE) in the UK. PPE Hive responds to increased PPE needs due to the Covid-19 crisis, with the aim of activating the capacity of grassroots efforts, independent and corporate institutions and government to adapt to these emergency needs. There is an urgent call for medical supplies to be fabricated rapidly and locally. Small scale producers have responded, and larger industries have started to convert their production capacities, but a smarter ecosystem is needed to support these connections across scales.
PPE Hive synchronizes existing networks - larger manufacturers, material suppliers, makers, delivery providers and healthcare services for the safe/quick production and delivery of PPE to medical, health and care workers. Our application connects demand and supply, facilitating production and delivery, while aiming to coordinate transaction logistics.
Led by Automated Architecture (AUAR), leaders in design and architecture innovation and experts in digital fabrication, and Calvium, leaders in the field of mobile innovation, PPE Hive will support essential health, care and medical workers, with the aim to soon expand capacity to local communities, during the Covid-19 crisis. PPE Hive aims to cultivate emerging UK circular economies, and through ongoing development and scale up, model data management and platform governance in the public interest, an issue which is central to widespread platform adoption.
The functionality of the application is divided in three categories: Offers, Requests and Transport. Offers represent available materials and equipment in the community pool. Users can post a new Offer or check what the community is providing, picking what suits their needs. Requests represent the demand for materials and equipment. Users can post a new Request or check what the community is needing and provide accordingly. Transport is taking help to those who need it. Users can claim an available transport task to assist your community.`;

const AboutProjectPage: FC = () => {
  return (
    <AppContainer>
      <BrandHeader iconName="previousBasic" to={URLS.information} />
      <Container className="p-0 limit-width text-center">
        <img src={aboutProject} alt="PPE Hive" className="img-fluid align-self-center" />
      </Container>
      <Container className="p-4 limit-width">
        <h1 className="py-3">About</h1>
        {about.split('\n').map(a => (
          <p>{a}</p>
        ))}
      </Container>
    </AppContainer>
  );
};

export default AboutProjectPage;
