import React, {FC} from 'react';

type Props = {
  className?: string;
  size?: number;
  color?: string;
};

const LogoutIcon: FC<Props> = props => {
  const {className, size = 54, color = 'black'} = props;
  return (
    <svg className={className} height={size} width={size} viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg">
      <filter id="a" height="183.3%" width="183.3%" x="-41.7%" y="-41.7%">
        <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          type="matrix"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(6 3)">
        <circle cx="21" cy="21" fill={color} r="21" />
        <g stroke="#fff" strokeWidth="3" transform="translate(10 10)">
          <g fillRule="evenodd" transform="translate(8.97561 6.170732)">
            <path d="m.280488.849084 12.341463-.007621" transform="translate(0 4.480184)" />
            <path d="m7.28627951 10.6713434 5.33567169-5.33567169-5.33567169-5.33567171" />
          </g>
          <path d="m12.3414634 16.9173921v4.1521632h-8.97560974v-18.82565286h8.97560974v4.50091814" />
        </g>
      </g>
    </svg>
  );
};
export default LogoutIcon;
