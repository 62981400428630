import React from 'react';
import {Row} from 'reactstrap';
import Icon from './Icon';
import {IconName} from '../icons';
import './SimpleGuide.css';

type PropsType = {
  icon: IconName;
  text: string;
};

const SimpleGuide = ({icon, text}: PropsType) => (
  <Row className="SimpleGuide align-items-center justify-content-center pl-5 pr-5 pt-3 pb-3 ml-0 mr-0">
    <Icon name={icon} size={80} />
    <p className="col ml-4">{text}</p>
  </Row>
);

export default SimpleGuide;
