import React, {useCallback, FC} from 'react';
import {Button} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {IconName} from '../icons';
import Icon from './Icon';

type Props = {
  text?: string;
  textComponent?: JSX.Element;
  iconName?: IconName;
  to?: string | (() => void);
  bottomMargin?: boolean;
};

const PageHeader: FC<Props> = props => {
  const {text, iconName, to, bottomMargin = true, textComponent} = props;
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (to !== undefined) {
      if (typeof to === 'function') {
        to();
      } else {
        history.push(to);
      }
    }
  }, [history, to]);

  return (
    <div className={'PageHeader p-3 border-bottom d-flex align-items-center' + (bottomMargin ? ' mb-3 ' : '')}>
      <div className={'flex-shrink-0'}>
        {to && iconName ? (
          <Button color="link" className={'p-0 btn-small mr-3'} onClick={handleClick}>
            <Icon name={iconName} size={54} />
          </Button>
        ) : (
          iconName && <Icon name={iconName} className={'mr-3'} size={64} />
        )}
      </div>
      <div className={'flex-grow-1'}>{textComponent ? textComponent : <h1 className={'m-0'}>{text}</h1>}</div>
    </div>
  );
};

export default PageHeader;
